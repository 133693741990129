<template>
    <div class="page" id="page12">
        <div class="head-title">五、劳动实践</div>
        <span class="part-section-title part-one">1.劳动印象</span>
        <!--  劳动印象 图表 -->
        <div id="labour-chart" class="labour-chart"></div>
        <span class="part-section-title part-two">2.劳动成绩</span>
        <table class="first-table">
            <tr class="grade-section">
                <th class="section-title">年级</th>
                <th colspan="2">1年级</th>
                <th colspan="2">2年级</th>
                <th colspan="2">3年级</th>
                <th colspan="2">4年级</th>
                <th colspan="2">5年级</th>
                <th colspan="2">6年级</th>
            </tr>
            <tr class="session-section">
                <td class="section-title">
                    <div class="diagonal-line"></div>
                    <span class="right-title">学期</span>
                    <span class="bottom-title">科目</span>
                </td>
                <td>上</td>
                <td>下</td>
                <td>上</td>
                <td>下</td>
                <td>上</td>
                <td>下</td>
                <td>上</td>
                <td>下</td>
                <td>上</td>
                <td>下</td>
                <td>上</td>
                <td>下</td>
            </tr>
            <tr class="class-section">
                <td class="section-title">劳动</td>
                <td class="section-row" v-for="item in terms" :key="item">
                    <span>优秀<br /><span class="teacher-name">张老师</span></span>
                </td>
            </tr>
            <tr class="class-section">
                <td class="section-title">信息<br>科技</td>
                <td class="section-row" v-for="item in terms" :key="item">
                    <span>优秀<br /><span class="teacher-name">张老师</span></span>
                </td>
            </tr>
            <tr class="class-section">
                <td class="section-title">综合<br>实践</td>
                <td class="section-row" v-for="item in terms" :key="item">
                    <span>优秀<br /><span class="teacher-name">张老师</span></span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-wordcloud";

export default {
    data() {
        return {
            terms: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        }
    },
    mounted() {
        var chart = echarts.init(document.getElementById("labour-chart"));
        chart.setOption({
            series: [
                {
                    type: "wordCloud",
                    shape: "circle",
                    keepAspect: false,
                    // maskImage: maskImage,
                    left: "center",
                    top: "center",
                    width: "100%",
                    height: "100%",
                    right: null,
                    bottom: null,
                    sizeRange: [14, 93],
                    rotationRange: [-90, 90],
                    rotationStep: 90,
                    gridSize: 8,
                    drawOutOfBound: false,
                    shrinkToFit: false,
                    layoutAnimation: true,
                    textStyle: {
                        fontFamily: "sans-serif",
                        fontWeight: "bold",
                        color: function () {
                            return (
                                "rgb(" +
                                [
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160),
                                ].join(",") +
                                ")"
                            );
                        },
                    },
                    emphasis: {
                        focus: "self",
                        textStyle: {
                            textShadowBlur: 10,
                            textShadowColor: "#333",
                        },
                    },
                    data: [
                        {
                            name: "Sam S Club",
                            value: 10000,
                            textStyle: {
                                color: "black",
                            },
                            emphasis: {
                                textStyle: {
                                    color: "red",
                                },
                            },
                        },
                        {
                            name: "Macys",
                            value: 6181,
                        },
                        {
                            name: "Amy Schumer",
                            value: 4386,
                        },
                        {
                            name: "Jurassic World",
                            value: 4055,
                        },
                        {
                            name: "Charter Communications",
                            value: 2467,
                        },
                        {
                            name: "Chick Fil A",
                            value: 2244,
                        },
                        {
                            name: "Planet Fitness",
                            value: 1898,
                        },
                        {
                            name: "Pitch Perfect",
                            value: 1484,
                        },
                        {
                            name: "Express",
                            value: 1112,
                        },
                        {
                            name: "Home",
                            value: 965,
                        },
                        {
                            name: "Johnny Depp",
                            value: 847,
                        },
                        {
                            name: "Lena Dunham",
                            value: 582,
                        },
                        {
                            name: "Lewis Hamilton",
                            value: 555,
                        },
                        {
                            name: "KXAN",
                            value: 550,
                        },
                        {
                            name: "Mary Ellen Mark",
                            value: 462,
                        },
                        {
                            name: "Farrah Abraham",
                            value: 366,
                        },
                        {
                            name: "Rita Ora",
                            value: 360,
                        },
                        {
                            name: "Serena Williams",
                            value: 282,
                        },
                        {
                            name: "NCAA baseball tournament",
                            value: 273,
                        },
                        {
                            name: "Point Break",
                            value: 265,
                        },
                    ],
                },
            ],
        });
    }
}
</script>

<style lang="less" scoped>
#page12 {
    background-image: url("../assets/page6_bg.png");
    background-size: cover;
    overflow: hidden;

    .head-title {
        margin-top: 127px;
        margin-left: 58px;
        font-size: 37px;
        font-weight: 700;
        line-height: 37px;
        color: #30744C;
    }

    .part-section-title {
        display: block;
        margin-left: 60px;


    }

    .part-one {
        margin-top: 100px;
    }

    .part-two {
        margin-top: 40px;
    }

    .labour-chart {
        margin-left: 30px;
        border-radius: 15px;
        height: 382px;
        width: 600px;
    }

    .first-table {
        margin-top: 31px;
        margin-left: 32px;
        text-align: center;
        width: 609px;
        border-collapse: collapse;

        .section-title {
            font-size: 15px;
            position: relative;

            .diagonal-line {
                position: absolute;
                top: 0;
                width: 70px;
                /* 调整线条的宽度 */
                height: 1px;
                /* 调整线条的高度 */
                background-color: #ddd;
                /* 调整线条的颜色 */
                transform: rotate(29.5deg);
                /* 旋转45度，以创建斜线效果 */
                transform-origin: 0% 0%;
                /* 设置旋转的原点为左上角 */
            }
        }

        .grade-section {
            height: 53px;
            font-size: 15px;

            & th {
                border: #ddd 1px solid;
                width: 91px;
                
                &:first-child {
                    width: 67px;
                }
            }
        }

        .session-section {
            height: 37px;
            font-size: 15px;

            td {
                width: 45.5px;

                &:first-child {
                    width: 67px;
                }
            }
        }

        .class-section {
            height: 63px;

            .section-row {
                font-size: 14px;
            }

            .teacher-name {
                font-size: 12px;
            }
        }

        td {
            border: #ddd 1px solid;
        }
    }

}</style>