<template>
  <div class="page" id="page18">
    <div class="school-name">重庆外国语森林小学</div>
    <img src="../assets/page18_line.png" alt="#" class="grade-line" />
    <span class="school-desc">办学理念：森林绿心，生活化人</span>
    <span class="school-desc">育人目标：培养有大树精神的强国少年</span>
    <span class="school-desc">校训：拥抱森林每片绿</span>
    <span class="school-desc">校风：向下扎根向上开花</span>
    <span class="school-desc">学风：真学真做真成长</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#page18 {
  background-image: url("../assets/page18_screen_bg.png");
  background-size: cover;
  padding-left: 90px;
  color: white;

  .school-name {
    font-size: 25px;
    font-weight: 500;
    padding-top: 171px;
  }

  .grade-line {
    width: 306px;
    height: 6px;
    margin-top: 18px;
    margin-bottom: 47px;
  }

  .school-desc {
    display: block;
    margin-top: 22px;
    font-size: 16px;
  }
}
</style>
