<template>
  <div class="page">
    <!-- 封面 -->
    <img :src="bgimg" alt="#" class="page-end" />
    <div class="stu-info">
      学生：{{ info.StuName }}<br />
      <!-- 学号：25<br /> -->
      班级：{{ info.ClassName }}<br />
      <!-- 班主任：{{ info.TeacherName }} -->
    </div>
    <!-- <img :src="headimg" alt="#" class="per-head"> -->
  </div>
</template>

<script>
export default {
  props: {
    info: {},
    Suyang: true,
    bgimg: "",
    headimg:""
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.page {
  overflow: hidden;

  .stu-info {
    font-size: 19px;
    color: #FFF;
    line-height: 41px;
    position: absolute;
    bottom: 150px;
    left: 115px;
    font-weight: 500;
  }

  .page-end {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }

.per-head{
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: absolute;
  bottom: 160px;
  right: 150px;
}

}
</style>