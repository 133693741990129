import { render, staticRenderFns } from "./IndividualStrength.vue?vue&type=template&id=872bf372&scoped=true&"
import script from "./IndividualStrength.vue?vue&type=script&lang=js&"
export * from "./IndividualStrength.vue?vue&type=script&lang=js&"
import style0 from "./IndividualStrength.vue?vue&type=style&index=0&id=872bf372&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "872bf372",
  null
  
)

export default component.exports