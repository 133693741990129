<template>
  <div class="page" id="page11">
    <span class="section-title-top">3.数据记录</span>
    <div class="chrat-content">
      <span class="chart-title"> 艺术活动记录 </span>
      <div id="chart-item"></div>
    </div>
    <span class="section-title">3.数据记录</span>
    <table class="first-table">
      <tr class="grade-section">
        <th class="section-title">年级</th>
        <th colspan="2">1年级</th>
        <th colspan="2">2年级</th>
        <th colspan="2">3年级</th>
        <th colspan="2">4年级</th>
        <th colspan="2">5年级</th>
        <th colspan="2">6年级</th>
      </tr>
      <tr class="session-section">
        <td class="section-title">学期</td>
        <td>上</td>
        <td>下</td>
        <td>上</td>
        <td>下</td>
        <td>上</td>
        <td>下</td>
        <td>上</td>
        <td>下</td>
        <td>上</td>
        <td>下</td>
        <td>上</td>
        <td>下</td>
      </tr>
      <tr class="class-section">
        <td class="section-title">语文</td>
        <td class="section-row" v-for="item in terms" :key="item">
          <span
            >优秀<br />
            <span class="teacher-name">张老师</span>
          </span>
        </td>
      </tr>
      <tr class="class-section">
        <td class="section-title">数学</td>
        <td class="section-row" v-for="item in terms" :key="item">
          <span>优秀<br /><span class="teacher-name">张老师</span></span>
        </td>
      </tr>
      <tr class="class-section">
        <td class="section-title">英语</td>
        <td class="section-row" v-for="item in terms" :key="item">
          <span>优秀<br /><span class="teacher-name">张老师</span></span>
        </td>
      </tr>
      <tr class="class-section">
        <td class="section-title">科学</td>
        <td class="section-row" v-for="item in terms" :key="item">
          <span>优秀<br /><span class="teacher-name">张老师</span></span>
        </td>
      </tr>
      <tr class="class-section">
        <td class="section-title">学期</td>
        <td class="section-row" v-for="item in terms" :key="item">
          <span>优秀<br /><span class="teacher-name">张老师</span></span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

export default {
  data() {
    return {
      terms: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
  },
  mounted() {
    var chartDom = document.getElementById("chart-item");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["课文阅读", "课外阅读", "英语阅读", "口算练习", "书写练习"],
        top: "20",
        textStyle: {
          color: "#3E3A39",
          fontStyle: "normal",
          fontSize: 12,
        },
      },
      grid: {
        left: "6%",
        right: "6%",
        bottom: "10%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["1年级", "2年级", "3年级", "4年级", "5年级", "6年级"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "课文阅读",
          type: "line",
          stack: "Total",
          data: [120, 132, 101, 134, 90, 230],
        },
        {
          name: "课外阅读",
          type: "line",
          stack: "Total",
          data: [220, 182, 191, 234, 290, 330],
        },
        {
          name: "英语阅读",
          type: "line",
          stack: "Total",
          data: [150, 232, 201, 154, 190, 330],
        },
        {
          name: "口算练习",
          type: "line",
          stack: "Total",
          data: [320, 332, 301, 334, 390, 330],
        },
        {
          name: "书写练习",
          type: "line",
          stack: "Total",
          data: [820, 932, 901, 934, 1290, 1330],
        },
      ],
    };

    option && myChart.setOption(option);
  },
};
</script>

<style lang="less" scoped>
#page11 {
  background-image: url("../assets/page8_bg.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  .section-title-top {
    margin-left: 56px;
    font-size: 16px;
    font-weight: 500;
    width: calc(100% - 54px);
    display: block;
    line-height: 16px;
    box-sizing: border-box;
  }

  .chrat-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 29px;

    #chart-item {
      width: 718px;
      height: 443px;
    }
  }

  .first-table {
    margin-top: 31px;
    text-align: center;
    width: 613px;
    border-collapse: collapse;

    .section-title {
      font-size: 15px;
    }

    .grade-section {
      height: 53px;
      font-size: 15px;

      & th {
        border: #ddd 1px solid;
        width: 91px;

        &:first-child {
          width: 67px;
        }
      }
    }

    .session-section {
      height: 37px;
      font-size: 15px;

      td {
        width: 45.5px;

        &:first-child {
          width: 67px;
        }
      }
    }

    .class-section {
      height: 63px;

      .section-row {
        font-size: 14px;
      }

      .teacher-name {
        font-size: 12px;
      }
    }

    td {
      border: #ddd 1px solid;
    }
  }
}
</style>