<template>
  <!-- 品德发展 -->
  <div id="moral-container">
    <project-name class="title-auto" title="品德发展" />
    <p class="dev-desc">
      <!-- 发展描述评语 -->
      品德是人之魂，品德习惯是行为之魂。做一个有道德的人，必须从一点一滴做起，践行道德规范、增强道德意识，养成良好习惯、培养高尚品质，希望你时时严格要求自己，做一个高素质的社会主义小公民。
    </p>
    <div class="dev-main">
      <!-- 发展主页 -->
      <div class="main-left">
        <div
          v-for="(item, index) in reals"
          :key="index"
          class="left-section-main"
        >
          <div class="left-main-title">{{ item.key }}</div>
          <!-- 左侧详细 -->
          <div class="left-sction" v-for="obj in item.datas" :key="obj.EvalId">
            <TermRate style="width: 125px" :current="obj.Star" />
            <span class="section-span">{{ obj.Dimension }}</span>
          </div>
        </div>
      </div>
      <div v-if="dePhotos && reals.length < 15" class="main-right">
        <!-- 右侧风采照 -->
        <img
          v-for="(item, index) in dePhotos"
          :key="index"
          :src="item.Photo"
          alt="#"
          class="dev-imgs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectName from "../components/term/ProjectName.vue";
import TermRate from "../components/term/TermRate.vue";
export default {
  components: {
    ProjectName,
    TermRate,
  },
  data() {
    return {
      value2: 5,
      reals: [],
      allItems: [],
    };
  },
  props: {
    evals: [],
    dePhotos: [],
  },
  created() {
    this.getLaos();
  },
  methods: {
    getLaos() {
      var keys = [];
      this.allItems = this.evals.filter((item) => item.Tag == "德").sort((a, b) => a.Sort - b.Sort);
      this.allItems.forEach((item) => {
        let index = keys.indexOf(item.Weidu);
        if (index < 0) {
          //如果没有
          keys.push(item.Weidu);
          this.reals.push({ key: item.Weidu, datas: [item] });
        } else {
          this.reals[index].datas.push(item);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#moral-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  .title-auto {
    margin: 0 auto;
    margin-top: 32px;
  }

  .dev-desc {
    margin: 0 24px;
    margin-top: 18px;
    color: #3e3a39;
    font-size: 16px;
    text-indent: 2em;
  }

  .dev-main {
    flex: 1;
    display: flex;
    justify-content: flex-start;

    .left-main-title {
      color: #3e3a39;
      font-size: 18px;
      font-weight: 500;
      margin: 16px 0;
    }

    .main-left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 32px;

      .left-section-main {
        display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          box-sizing: border-box;
          flex: 1;

        .left-sction {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          max-height: 40px;
          min-height: 28px;
          box-sizing: border-box;
          flex: 1;

          .section-span {
            color: #3e3a39;
            line-height: 25px;
            font-size: 18px;
            margin-left: 32px;
          }
        }
      }
    }

    .main-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-top: 32px;

      .dev-imgs {
        width: 186px;
        height: 139px;
        border-radius: 9px;
        object-fit: cover;
        margin-bottom: 27px;
      }
    }
  }
}
</style>
