<template>
  <div>
    <Page1></Page1>
    <Page2></Page2>
    <Page3></Page3>
    <Page4></Page4>
    <Page5></Page5>
    <Page6></Page6>
    <Page7></Page7>
    <Page8></Page8>
    <Page9></Page9>
    <Page10></Page10>
    <Page11></Page11>
    <Page12></Page12>
    <Page13></Page13>
    <Page14></Page14>
    <Page15></Page15>
    <Page18></Page18>
  </div>
</template>

<script>
import Page1 from "../views/page1.vue";
import Page2 from "../views/page2.vue";
import Page3 from "../views/page3.vue";
import Page4 from "../views/page4.vue";
import Page5 from "../views/page5.vue";
import Page6 from "../views/page6.vue";
import Page7 from "../views/page7.vue";
import Page8 from "../views/page8.vue";
import Page9 from "../views/page9.vue";
import Page10 from "../views/page10.vue";
import Page11 from "../views/page11.vue";
import Page12 from "../views/page12.vue";
import Page13 from "../views/page13.vue";
import Page14 from "../views/page14.vue";
import Page15 from "../views/page15.vue";
import Page18 from "../views/page18.vue";
export default {
  name: "Home",
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    Page12,
    Page13,
    Page14,
    Page15,
    Page18
  }
};
</script>

<style lang="less" scoped>
</style>
