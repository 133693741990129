import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    themType:'organe',
    stuMap: JSON.parse(sessionStorage.getItem("COMPANY_AUTH_INFO")) || ""
  },
  mutations: {
    changeThemType(state, payload) {
      state.themType = payload.type
    },
    changeStuMap(state, payloadStu) {
      state.stuMap = payloadStu.map
      sessionStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(payloadStu.map))
    }
  },
  actions: {
    setStuMap({commit}, payload) {
      return new Promise(resolve => {
        commit('changeStuMap', payload);
        resolve();
      });
    },

  },
  modules: {

  }
})
