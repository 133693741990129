<template>
  <div :class="['page', bagc]" id="home-container">
    <!-- <img v-if="hiddenMark" class="page-mark-left" :src="pageMark" alt="#" />
    <img v-if="hiddenMark" class="page-mark-right" :src="pageMark" alt="#" /> -->
    <div class="home-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    hiddenMark: {
      default: true
    },
    SchoolId:''
  },
  computed: {
    ...mapState(["themType"]),
    bagc() {
      if (typeof this.themType == "undefined") {
        return "bgc-green";
      } else if (this.themType == "green") {
        return "bgc-green";
      } else if (this.themType == "blue") {
        return "bgc-blue";
      } else {
        return this.getOrangeColor();
      }
    },
    pageMark() {
      if (typeof this.themType == "undefined") {
        return require("../assets/term/term_page_mark.png");
      } else if (this.themType == "green") {
        return require("../assets/term/term_page_mark.png");
      } else if (this.themType == "blue") {
        return require("../assets/term/term_blue_mark.png");
      } else {
        return require("../assets/term/term_organe_mark.png");
      }
    },
  },
  methods:{
    getOrangeColor() {
      return this.SchoolId == '50' ? 'bgc-orangr-color' : 'bgc-organe'
    }
  }
};
</script>

<style lang="less" scoped>
#home-container {
  padding-top: 66px;
  .page-mark-left {
    position: absolute;
    left: 93px;
    top: 13px;
    width: 25px;
    height: 93px;
    z-index: 99;
  }

  .page-mark-right {
    position: absolute;
    right: 93px;
    top: 13px;
    width: 25px;
    height: 93px;
    z-index: 99;
  }

  .home-main {
    border-radius: 13px;
    background-color: #fff;
    width: 722px;
    height: 1000px;
    margin: 0 auto;
    overflow: hidden;
  }
}

.bgc-green {
  background: linear-gradient(90deg, #329851 0%, #2fa984 100%);
}

.bgc-blue {
  background: linear-gradient(90deg, #47a1fc 0%, #4468b7 100%);
}

.bgc-organe {
  background: linear-gradient(238deg, #ff9a72 0%, #E2690D 63%, #E2690D 100%);
}

.bgc-orangr-color {
  background: #ed6c00;
}
</style>
