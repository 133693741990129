<template>
  <div class="container">
    <!-- 中间内容 -->
    <div class="content-title">
      <!-- 头部标题 -->
      <div class="school-title">
        <img :src="info.Logo" alt="#" class="title-img" />
        {{ info.SchoolName }}
      </div>
      <div class="title-infos">
        <div class="years-date">
          {{ info.AcdemicYear }}年度 ({{ info.Semester == 2 ? "下" : "上" }})
          学期
        </div>
        <div class="infos-desc">综合素质报告</div>
      </div>
    </div>
    <div class="term-info">
      <!-- 中部 学生信息 -->
      <div class="stu-name">{{ info.StuName }}</div>
      <div class="stu-infos">
        {{ info.Gender }} &nbsp; {{ info.TraditionalClassName }} &nbsp;
        <!-- 班主任:{{ info.TeacherName }} -->
      </div>
    </div>
    <div class="stu-imgs">
      <!-- 班级图片 -->
      <img :src="info.Image1" alt="#" class="imgs-item" />
      <img :src="info.Image2" alt="#" class="imgs-item" />
      <img :src="info.Image3" alt="#" class="imgs-item" />
    </div>
    <div v-if="Suyang" class="class-score">
      <!-- 成绩结果 -->
      <ProjectName class="score-name" title="学科素养" />
      <div class="score-list">
        <!-- 学科成绩列表 -->
        <div id="score-left"></div>
        <div id="score-right"></div>
      </div>
    </div>
    <div v-if="Suyang" class="bottom-foo">
      <div class="per-mark"></div>
      <div class="per-title">个人</div>
      <div class="clas-mark"></div>
      <div class="per-title">班级均值</div>
      <div class="grad-mark"></div>
      <div class="per-title">年级均值</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { ToolboxComponent, LegendComponent } from "echarts/components";
import { PieChart, RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  ToolboxComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  RadarChart,
]);

import ProjectName from "../components/term/ProjectName.vue";
export default {
  components: {
    ProjectName,
  },
  props: {
    info: {},
    Suyang: true,
  },
  computed: {
    projects() {
      return info.OpenLession;
    },
    leftData() {
      return this.info.Suyang.TotelLevel.personData.map((item) => {
        return { name: item.course, value: item.score };
      });
    },
  },
  data() {
    return {};
  },
  watch: {
    info() {
      if (this.Suyang) {
        this.updateLeftChart();
        this.updateRightChart();
      }
    },
  },
  mounted() {
    this.updateLeftChart();
    this.updateRightChart();
  },
  methods: {
    updateLeftChart() {
      var chartDom = document.getElementById("score-left");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        series: [
          {
            name: "Area Mode",
            type: "pie",
            radius: [20, 100],
            center: ["50%", "50%"],
            roseType: "area",
            data: this.leftData,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    updateRightChart() {
      var chartDom = document.getElementById("score-right");
      var myChart = echarts.init(chartDom);
      var option;
      let inds = this.info.Suyang.TotelLevel.personData.map((item) => {
        return { name: item.course, max: 6 };
      });
      let classs = this.info.Suyang.TotelLevel.classData.map((item) => {
        return item.score;
      });
      let grade = this.info.Suyang.TotelLevel.gradeData.map((item) => {
        return item.score;
      });
      let person = this.info.Suyang.TotelLevel.personData.map((item) => {
        return item.score;
      });
      option = {
        color: ["#5B73F5", "#33AECF", "#ED2176"],
        radar: {
          shape: "circle",
          indicator: inds,
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: grade,
                name: "年级均线",
              },
              {
                value: classs,
                name: "班级均线",
              },
              {
                value: person,
                name: "个人",
                areaStyle: {
                  color: "rgba(237, 33, 118, 0.5)",
                },
              },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 13px;
  background-color: #fff;
  width: 722px;
  height: 100%;

  .school-title {
    // 学校名称
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: "Times New Roman", Times, serif;
    font-weight: 900;
    line-height: 19px;
    margin-top: 32px;

    .title-img {
      width: 85px;
      height: 85px;
    }
  }

  .title-infos {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 28px;
    margin: 24px;

    .years-date {
      border-right: 1px solid #acb5b2;
      padding: 0 27px;
    }

    .infos-desc {
      padding: 0 27px;
    }
  }

  .term-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid #acb5b2;
    border-bottom: 1px solid #acb5b2;
    padding: 20px 0;
    width: 471px;
    box-sizing: border-box;

    .stu-name {
      font-size: 32px;
      line-height: 40px;
    }

    .stu-infos {
      font-size: 24px;
      line-height: 29px;
      margin-top: 21px;
    }
  }

  // 图片组
  .stu-imgs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .imgs-item {
      width: 140px;
      height: 105px;
      border-radius: 7px;
      margin-right: 27px;
      object-fit: cover;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .class-score {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 33px;

    .score-name {
      margin: 28px 0;
    }

    .score-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      #score-left {
        width: 50%;
        height: 380px;
      }

      #score-right {
        width: 50%;
        height: 380px;
      }
    }
  }
  .bottom-foo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .per-mark {
      width: 17px;
      height: 5px;
      background-color: #ed2176;
      opacity: 0.2;
    }

    .clas-mark {
      background: #33aecf;
      height: 3px;
      width: 17px;
    }

    .grad-mark {
      background: #5b73f5;
      height: 3px;
      width: 17px;
    }

    .per-title {
      color: #302d2c;
      font-size: 11px;
      margin-right: 35px;
      margin-left: 4px;
    }
  }
}
</style>
