<template>
    <div class="page" id="page2">
    </div>
  </template>
    
  <script>
  export default {
    name: "Home",
    components: {},
  };
  </script>
    
  <style lang="less" scoped>
  #page2 {
    background-image: url("../assets/page2_bg.png");
    background-size: cover;
  }
  </style>
    