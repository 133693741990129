<template>
  <div class="page" id="page6">
    <div class="head-title">二、学业发展</div>
    <div class="section-first">
      <div class="section-title">1.学科成绩</div>
      <div class="first-table">
        <table>
          <tr class="grade-section">
            <th class="section-title">年级</th>
            <th colspan="2">1年级</th>
            <th colspan="2">2年级</th>
            <th colspan="2">3年级</th>
            <th colspan="2">4年级</th>
            <th colspan="2">5年级</th>
            <th colspan="2">6年级</th>
          </tr>
          <tr class="session-section">
            <td class="section-title">学期</td>
            <td>上</td>
            <td>下</td>
            <td>上</td>
            <td>下</td>
            <td>上</td>
            <td>下</td>
            <td>上</td>
            <td>下</td>
            <td>上</td>
            <td>下</td>
            <td>上</td>
            <td>下</td>
          </tr>
          <tr class="class-section">
            <td class="section-title">语文</td>
            <td class="section-row" v-for="item in terms" :key="item">
              <span>优秀<br />
                <span class="teacher-name">张老师</span>
              </span>
            </td>
          </tr>
          <tr class="class-section">
            <td class="section-title">数学</td>
            <td class="section-row" v-for="item in terms" :key="item"><span>优秀<br /><span
                  class="teacher-name">张老师</span></span></td>
          </tr>
          <tr class="class-section">
            <td class="section-title">英语</td>
            <td class="section-row" v-for="item in terms" :key="item"><span>优秀<br /><span
                  class="teacher-name">张老师</span></span></td>
          </tr>
          <tr class="class-section">
            <td class="section-title">科学</td>
            <td class="section-row" v-for="item in terms" :key="item"><span>优秀<br /><span
                  class="teacher-name">张老师</span></span></td>
          </tr>
          <tr class="class-section">
            <td class="section-title">学期</td>
            <td class="section-row" v-for="item in terms" :key="item"><span>优秀<br /><span
                  class="teacher-name">张老师</span></span></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="section-next">
      <div class="section-title">2.日常学习</div>
      <div class="content-title">日常学习活动记录</div>
      <div id="next-charts"></div>
    </div>
  </div>
</template>
    
<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

export default {
  name: "Home",
  components: {},
  data() {
    return {
      terms: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  },
  mounted() {
    var chartDom = document.getElementById("next-charts");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["课文阅读", "课外阅读", "英语阅读", "口算练习", "书写练习"],
        top: "20",
        textStyle: {
          color: "#3E3A39",
          fontStyle: "normal",
          fontSize: 12
        }
      },
      grid: {
        left: "2%",
        right: "2%",
        bottom: "2%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["1年级", "2年级", "3年级", "4年级", "5年级", "6年级"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "课文阅读",
          type: "line",
          stack: "Total",
          data: [120, 132, 101, 134, 90, 230],
        },
        {
          name: "课外阅读",
          type: "line",
          stack: "Total",
          data: [220, 182, 191, 234, 290, 330],
        },
        {
          name: "英语阅读",
          type: "line",
          stack: "Total",
          data: [150, 232, 201, 154, 190, 330],
        },
        {
          name: "口算练习",
          type: "line",
          stack: "Total",
          data: [320, 332, 301, 334, 390, 330],
        },
        {
          name: "书写练习",
          type: "line",
          stack: "Total",
          data: [820, 932, 901, 934, 1290, 1330],
        },
      ],
    };

    option && myChart.setOption(option);
  },
};
</script>
    
<style lang="less" scoped>
#page6 {
  background-image: url("../assets/page6_bg.png");
  background-size: cover;
  overflow: hidden;
  
  .head-title {
    margin-top: 127px;
    margin-left: 58px;
    font-size: 37px;
    font-weight: 700;
    color: #30744C;
  }

  .section-first {
    margin-top: 80px;
    margin-left: 27px;

    .section-title {
      color: #231815;
      font-size: 16px;
      margin-left: 32px;
    }

    .first-table {
      margin-top: 31px;
      text-align: center;

      table {
        width: 613px;

        .section-title {
          font-size: 15px;
        }

        .grade-section {
          height: 53px;
          font-size: 15px;

          & th {
            border: #ddd 1px solid;
            width: 91px;

            &:first-child {
              width: 67px;
            }

          }
        }

        .session-section {
          height: 37px;
          font-size: 15px;

          td {
            width: 45.5px;

            &:first-child {
              width: 67px;
            }

          }
        }

        .class-section {
          height: 63px;

          .section-row {
            font-size: 14px;
          }

          .teacher-name {
            font-size: 12px;
          }

        }

        td {
          border: #ddd 1px solid;
        }

      }

    }

  }

  .section-next {
    .section-title {
      margin-left: 58px;
      margin-top: 39px;
      font-size: 16px;
    }

    .content-title {
      font-size: 16px;
      margin-left: 294px;
    }

  }

  #next-charts {
    width: 580px;
    height: 260px;
    margin-left: 40px;
  }

}

table {
  border-collapse: collapse;
  border: #ddd 1px solid;
  background-color: #eee;
}
</style>
    