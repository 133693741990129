<template>
  <div class="page">
    <!-- 目录 -->
    <img :src="bgimage" alt="#" class="page-bg" />
    <div class="list-content">
      <!-- <div class="list-title">目录</div> -->
      <div class="section-content">
        <div v-for="(item, index) in list" class="list-section" :key="index">
          {{ getIndex(index) }}、{{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    list: [],
    bgimage: "",
  },
  data() {
    return {};
  },
  methods: {
    getIndex(index) {
      switch (index) {
        case 0:
          return "一";
        case 1:
          return "二";
        case 2:
          return "三";
        case 3:
          return "四";
        case 4:
          return "五";
        case 5:
          return "六";
        case 6:
          return "七";
        case 7:
          return "八";
        case 8:
          return "九";
        case 9:
          return "十";
        case 10:
          return "十一";
        case 11:
          return "十二";
        case 12:
          return "十三";
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.page {
  .page-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  .list-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 350px;
    padding-left: 55px;
    box-sizing: border-box;

    .section-content {
      font-size: 20px;
      color: #ffffff;
      line-height: 54px;
      font-weight: 500;
      height: 54px;
      margin-top: 260px;
    }
  }
}
</style>