<template>
  <div class="rate-container">
    <img v-for="index in rateNums" :key="index" :src="getStarImg(index)" alt="#" class="item-img">
  </div>
</template>

<script>
export default {
  props: {
    current: 0
  },
  computed:{
    rateNums() {
        return [...new Array(5)].map((_,index) => index + 1)
    }
  },
  data() {
    return {};
  },
  methods:{
    getStarImg(index) {
      return index <= this.current ? require("../../assets/term/term_rate.png") : require("../../assets/term/term_rate_un.png")
    }
  }
};
</script>

<style lang="less" scoped>
.rate-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;

   .item-img{
    width: 21px;
    height: 21px;
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }

   } 

}
</style>
