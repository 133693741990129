<template>
  <div class="art-container">
    <!-- 艺术素养 -->
    <project-name class="title-auto" title="艺术素养" />
    <div class="music-main">
      <!-- 音乐 -->
      <div class="music-left">
        <div id="music-charts"></div>
        <section v-for="(item, index) in yinWeiDus.slice(0, 2)" :key="index">
          <div class="music-title">{{ index + 1 }} {{ item.key }}</div>
          <div class="section-spans">
            <span
              v-for="(obj, idx) in item.datas"
              :key="idx"
              class="spans-desc"
              >{{ obj.Dimension }}</span
            >
          </div>
        </section>
      </div>
      <div v-if="yinWeiDus.length > 2" class="music-right">
        <!-- 维度列表 -->
        <section
          v-for="(item, index) in yinWeiDus.slice(-(yinWeiDus.length - 2))"
          :key="index"
        >
          <div class="music-title">{{ index + 3 }} {{ item.key }}</div>
          <div class="section-spans">
            <span
              v-for="(obj, idx) in item.datas"
              :key="idx"
              class="spans-desc"
              >{{ obj.Dimension }}</span
            >
          </div>
        </section>
      </div>
      <div class="mei-mark">音乐</div>
    </div>
    <div class="music-imgs">
      <!-- 图片列表 -->
      <div v-if="this.ReportPhotos.YinCodePhoto.length" class="qr-conte">
        <!-- 二维码 -->
        <div id="qr-main" ref="qrCodeUrl"></div>
        扫码欣赏音乐
      </div>
      <img
        v-for="(item, index) in MeiPhotos"
        :key="index"
        :src="item.Photo"
        alt="#"
        class="imgs-item"
      />
    </div>
    <div class="mei-main">
      <!-- 美术 -->
      <section v-for="(item, index) in meiWeiDus.slice(0, 3)" :key="index">
        <div class="mei-title">{{ index + 1 }} {{ item.key }}</div>
        <div class="mei-desc">
          <span
            v-for="(obj, idx) in item.datas"
            :key="idx"
            class="spans-desc"
            >{{ obj.Dimension }}</span
          >
        </div>
      </section>
      <div class="mei-foot">
        <div v-if="meiWeiDus.length > 3" class="desc-foot">
          <!-- 其余维度 -->
          <section
            v-for="(item, index) in meiWeiDus.slice(-(meiWeiDus.length - 3))"
            :key="index"
          >
            <div class="mei-title">{{ index + 4 }} {{ item.key }}</div>
            <div class="mei-desc">
              <span
                v-for="(obj, idx) in item.datas"
                :key="idx"
                class="spans-desc"
                >{{ obj.Dimension }}</span
              >
            </div>
          </section>
        </div>
        <div class="foot-chart">
          <ArtisZhuChart :ReportEvalSum="ReportEvalSum" />
        </div>
      </div>
      <div class="mei-mark">美术</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import QRCode from "qrcodejs2";
import ProjectName from "../components/term/ProjectName.vue";
import ArtisZhuChart from "../components/term/ArtisZhuChart.vue";
export default {
  components: {
    ProjectName,
    ArtisZhuChart,
  },
  props: {
    evals: {},
    ReportPhotos: {},
    ReportEvalSum: [],
  },
  computed: {
    MeiPhotos() {
      return this.ReportPhotos.MeiPhotos.slice(0, 3);
    },
  },
  data() {
    return {
      stars: [],
      meiWeiDus: [],
      getMeiSuDesc: [],
      yinWeiDus: [],
      getYinSuDesc: [],
    };
  },
  mounted() {
    this.getYinSuDesc = this.evals
      .filter((obj) => obj.Course == "音乐")
      .sort((a, b) => a.Sort - b.Sort);
    this.getMeiSuDesc = this.evals
      .filter((obj) => obj.Course == "美术")
      .sort((a, b) => a.Sort - b.Sort);
    this.creatQrCode();
    this.getMeiAllWeiDu();
    this.getYinAllWeiDu();
    this.getMusicChartStars();
    this.getBanYuanChart();
  },
  methods: {
    getBanYuanChart() {
      var myChart2 = echarts.init(document.getElementById("music-charts"));
      let maxs = new Array(this.stars.length).fill(5)
      let datas = new Array(this.stars.length).fill(0).map( (i, index) => index+1)
      myChart2.setOption({
        polar: {
          radius: ["5%", "100%"],
          center: ["50%", "50%"],
        },
        angleAxis: {
          startAngle: 180,
          max: 10,
          show: false,
        },
        radiusAxis: {
          type: "category",
          data: datas,
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            z: 2,
            type: "bar",
            colorBy: "data",
            color: ["#E6A083", "#DB8468", "#E07046", "#C96341", "#CE4F32"],
            data: this.stars,
            roundCap: true,
            coordinateSystem: "polar",
            barGap: "-100%",
            barWidth: 10,
          },
          {
            z: 1,
            type: "bar",
            colorBy: "data",
            data: maxs,
            itemStyle: {
              color: "#DCDFE7",
            },
            roundCap: true,
            coordinateSystem: "polar",
            barGap: "-100%",
            barWidth: 10,
          },
        ],
      });
    },
    creatQrCode() {
      if (this.ReportPhotos.YinCodePhoto.length) {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.ReportPhotos.YinCodePhoto[0].Vedio,
          width: 70,
          height: 70,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      }
    },
    getMeiAllWeiDu() {
      // 美术维度
      var keys = [];
      this.getMeiSuDesc.forEach((item) => {
        let index = keys.indexOf(item.Weidu);
        if (index < 0) {
          //如果没有
          keys.push(item.Weidu);
          this.meiWeiDus.push({ key: item.Weidu, datas: [item] });
        } else {
          this.meiWeiDus[index].datas.push(item);
        }
      });
    },
    getYinAllWeiDu() {
      // 音乐维度
      var keys = [];
      this.getYinSuDesc.forEach((item) => {
        let index = keys.indexOf(item.Weidu);
        if (index < 0) {
          //如果没有
          keys.push(item.Weidu);
          this.yinWeiDus.push({ key: item.Weidu, datas: [item] });
        } else {
          this.yinWeiDus[index].datas.push(item);
        }
      });
    },
    getMusicChartStars() {
      // 音乐维度
      this.yinWeiDus.forEach((item) => {
        let objs = this.ReportEvalSum.filter((data) => data.Weidu == item.key);
        if (objs.length) {
          this.stars.push(objs[0].Star);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.art-container {
  .title-auto {
    margin: 0 auto;
    margin-top: 42px;
  }

  .music-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    margin-top: 32px;

    .music-left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      flex: 0.5;
      section {
        margin-left: 32px;
      }

      #music-charts {
        width: 305px;
        height: 140px;
      }
    }

    .music-right {
      margin: 0 32px;
      flex: 0.5;
      section {
        margin-bottom: 4px;
      }
    }

    .music-title {
      color: #1a1311;
      font-size: 16px;
      font-weight: 500;
    }

    .section-spans {
      color: #1a1311;
      font-size: 13px;
    }
  }

  .music-imgs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px;

    .qr-conte {
      width: 108px;
      height: 108px;
      background: #ededed;
      border-radius: 11px;
      font-size: 13px;
      color: #302d2c;
      line-height: 27px;
      text-align: center;

      #qr-main {
        background-color: #fff;
        padding: 1px;
        box-sizing: border-box;
        width: 72px;
        height: 72px;
        margin: 0 auto;
        margin-top: 11px;
      }
    }

    .imgs-item {
      width: 143px;
      height: 108px;
      border-radius: 11px;
      margin-left: 27px;
      object-fit: cover;
    }
  }

  .mei-main {
    position: relative;

    section {
      color: #3e3a39;
      margin-left: 62px;
      margin-top: 10px;

      .mei-title {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .mei-desc {
      font-size: 13px;
      margin-right: 42px;
    }

    .mei-foot {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;

      .desc-foot {
        flex: 0.5;
      }

      .foot-chart {
        flex: 0.5;
      }

      .mei-desc {
        margin-right: 0;
      }
    }
  }

  .mei-mark {
    width: 21px;
    height: 56px;
    font-size: 21px;
    color: #303836;
    line-height: 27px;
    position: absolute;
    left: 27px;
    top: 2px;
  }
}
</style>
