<template>
  <div class="art-chart">
    <div id="chart-main"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([GridComponent, BarChart, CanvasRenderer]);

export default {
  props: {
    ReportEvalSum: [],
  },
  computed: {
    getMeiCourses() {
      return this.ReportEvalSum.filter((item) => item.Course == "美术");
    },
    getXAxis() {
      return this.getMeiCourses.map((item) => item.Weidu);
    },
    getYAxis() {
      return this.getMeiCourses.map((item) => item.Star);
    },
    chartRotate(){
      return this.getXAxis.length < 3 ? 0 : 50
    }
  },
  data() {
    return {};
  },
  mounted() {
    var chartDom = document.getElementById("chart-main");
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      xAxis: {
        type: "category",
        data: this.getXAxis,
        axisLabel: { interval: 0, rotate: this.chartRotate }
      },
      yAxis: {
        type: "value",
        max: 5
      },
      series: [
        {
          data: this.getYAxis,
          type: "bar",
          barWidth: "20%",
          itemStyle: {
            color: "#62AADE",
          },
        },
      ],
    };

    option && myChart.setOption(option);
  },
};
</script>

<style lang="less" scoped>
.art-chart {
  #chart-main {
    margin: 0 auto;
    width: 100%;
    height: 200px;
  }
}
</style>
