<template>
  <div class="container">
    <div v-if="Suyang" class="class-score">
      <!-- 成绩结果 -->
      <ProjectName class="score-name" title="学科素养" />
      <!-- 学科成绩列表 -->
      <div id="score-right"></div>
    </div>
    <div v-if="Suyang" class="bottom-foo">
      <div class="per-mark"></div>
      <div class="per-title">个人</div>
      <div class="clas-mark"></div>
      <div class="per-title">班级均值</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { ToolboxComponent, LegendComponent } from "echarts/components";
import { PieChart, RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  ToolboxComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  RadarChart,
]);

import ProjectName from "../components/term/ProjectName.vue";
export default {
  components: {
    ProjectName,
  },
  props: {
    info: {},
    Suyang: true,
  },
  computed: {
    projects() {
      return info.OpenLession;
    },
  },
  data() {
    return {};
  },
  watch: {
    info() {
      if (this.Suyang) {
        this.updateRightChart();
      }
    },
  },
  mounted() {
    this.updateRightChart();
  },
  methods: {
    updateRightChart() {
      var chartDom = document.getElementById("score-right");
      var myChart = echarts.init(chartDom);
      var option;
      let inds = this.info.Suyang.TotelLevel.personData.map((item) => {
        return { name: item.course, max: 6 };
      });
      let classs = this.info.Suyang.TotelLevel.classData.map((item) => {
        return item.score;
      });
      let person = this.info.Suyang.TotelLevel.personData.map((item) => {
        return item.score;
      });
      option = {
        color: ["#33AECF", "#ED2176"],
        radar: {
          shape: "circle",
          indicator: inds,
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: classs,
                name: "班级均线",
              },
              {
                value: person,
                name: "个人",
                areaStyle: {
                  color: "rgba(237, 33, 118, 0.5)",
                },
              },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 13px;
  background-color: #fff;
  width: 722px;
  height: 100%;

  .class-score {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 33px;

    .score-name {
      margin: 28px 0;
    }

    #score-right {
        width: 600px;
        height: 600px;
      }

  }
  .bottom-foo {
    display: flex;
    justify-content: center;
    align-items: center;

    .per-mark {
      height: 8px;
      width: 30px;
      background-color: #ed2176;
      opacity: 0.2;
    }

    .clas-mark {
      background: #33aecf;
      height: 8px;
      width: 30px;
    }

    .grad-mark {
      background: #5b73f5;
      height: 8px;
      width: 30px;
    }

    .per-title {
      color: #302d2c;
      font-size: 16px;
      margin-right: 35px;
      margin-left: 4px;
    }
  }
  
}
</style>
