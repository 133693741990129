<template>
  <div class="page">
    <!-- 封底 -->
    <img :src="bgimage" alt="#" class="page-end" />
  </div>
</template>
  
  <script>
export default {
  props: {
    bgimage: "",
  },
  data() {
    return {};
  },
};
</script>
  
  <style lang="less" scoped>
.page {
  background-color: #fff;
  overflow: hidden;
  
  .page-end {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}
</style>