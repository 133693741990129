<template>
  <div class="contanier">
    <!-- 红领巾奖章 -->
    <div class="title-pack">
      <project-name title="红领巾奖章" />
    </div>
    <div v-if="zhangPhotos.length" class="medal-content">
      <!-- 奖章明细 -->
      <!-- <div class="section-content">
        <div class="content-span">入队时间:2019年6月</div>
        <div class="content-span">所在中队:四年级1班中队</div>
      </div> -->
      <div class="section-content">
        <div class="content-span">
          本期奖章总数:{{ baseZhang + teseZhang }}枚
        </div>
        <div class="content-span">获得校级章:{{ baseZhang }}枚</div>
        <div class="content-span">获得雏鹰章:{{ teseZhang }}枚</div>
      </div>
      <div class="zhang-list">
        <div
          v-for="(item, index) in zhangPhotos"
          :key="index"
          class="img-content"
        >
          <img
            :src="item.MedalIcon"
            alt="#"
            class="img-zhang"
            :style="{
              width: getZhangImgSizeW + 'px',
              height: getZhangImgSizeW + 'px',
            }"
          />
          <span class="zhang-title" :style="{ fontSize: getZhangTitleSize }">{{
            item.MedalType
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectName from "./ProjectName.vue";
export default {
  components: {
    ProjectName,
  },
  props: {
    baseZhang: 0,
    teseZhang: 0,
    zhangPhotos: [],
  },
  computed: {
    getZhangImgSizeW() {
      if (this.zhangPhotos.length > 7) {
        return 74;
      } else {
        let sizeW = 700 / this.zhangPhotos.length;
        return sizeW > 170 ? 170 : sizeW;
      }
    },
    getZhangTitleSize() {
      return this.getZhangImgSizeW / 7.4 + "px";
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.contanier {
  width: 100%;
  .title-pack {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .medal-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-size: 19px;
    width: 100%;

    .section-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 80%;
      height: 35px;
    }

    .content-span {
      margin-right: 27px;
    }

    .zhang-list {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      .img-content {
        position: relative;

        .img-zhang {
          max-width: 170px;
          max-height: 170px;
        }

        .zhang-title {
          position: absolute;
          top: 58%;
          left: 50%;
          transform: translateX(-50%);
          color: white;
        }
      }
    }
  }
}
</style>
