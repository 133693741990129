<template>
  <div id="charts-content" ref="chartsContent"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

export default {
  name: "LineChartsItem",
  mounted() {
    var chartDom = document.getElementById("charts-content");
    var myChart = echarts.init(this.$refs.chartsContent);
    var option;

    option = {
      xAxis: {
        type: "category",
        data: ["1年级", "2年级", "3年级", "4年级", "5年级", "6年级"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: "line",
          smooth: true,
        },
      ],
    };

    option && myChart.setOption(option);
  },
};
</script>

<style lang="less">
#charts-content {
  //   background-color: #fff;
  width: 100%;
  height: 100%;
}
</style>
