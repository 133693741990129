
<template>
  <div class="container page">
    <!-- 校长寄语 -->
    <img :src="bgimage" alt="#" class="school-message" />
  </div>
</template>
  
  <script>
export default {
  props: {
    bgimage: "",
  },
  data() {
    return {};
  },
};
</script>
  
  <style lang="less" scoped>
.container {
  .school-message {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}
</style>