<template>
  <!-- 学科成绩 -->
  <div class="subject-container">
    <project-name class="title-auto" title="学科成绩" />
    <div>
      <!-- 第一个表格 -->
      <table v-for="(item, index) in OpenLession"
      :key="index" border="3" class="course-table">
        <tbody>
         <tr >
            <td rowspan="2" class="course-name">{{item.CourseName}}</td>
            <td  v-for="(obj, idx) in getProgressItem(item.CourseName)"
            :key="idx">{{ obj.name }}</td>
          </tr>
          <tr v-if="getShowBottom(item.CourseName)">
            <td v-for="(obj, idx) in getProgressItem(item.CourseName)"
            :key="idx">{{ getLevel(obj.value) }}</td>
          </tr>
          <tr v-else class="course-bottom">{{item.TotalLevel}}</tr>
        </tbody>
      </table>

      <!-- 第二个表格 -->
      <table v-for="(item, index) in ScoreShetuan"
      :key="index" border="3" class="course-table">
        <tbody>
         <tr >
            <td rowspan="2" class="course-name">{{item.CourseName}}</td>
            <td  v-for="(obj, idx) in getProgressItem(item.CourseName)"
            :key="idx">{{ obj.name }}</td>
          </tr>
          <tr v-if="getShowBottom(item.CourseName)">
            <td v-for="(obj, idx) in getProgressItem(item.CourseName)"
            :key="idx">{{ getLevel(obj.value) }}</td>
          </tr>
          <tr v-else class="course-bottom">{{item.TotalLevel}}</tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ProjectName from "../components/term/ProjectName.vue";
export default {
  components: {
    ProjectName,
  },
  props: {
    courseLevelList: [],
    OpenLession: [],
    ScoreShetuan: [],
  },
  data() {
    return {
    };
  },
  methods: {
    getShowBottom(name) {
      if (this.courseLevelList == undefined) {
        return false;
      }
      return (
        this.courseLevelList.filter((item) => item.course == name).length > 0
      );
    },
    getProgressItem(name) {
      if (this.courseLevelList == undefined) {
        return [];
      }
      let list = this.courseLevelList.filter((item) => item.course == name);
      return list.length > 0 ? list[0].keyCompetencies : [];
    },
    getLevel(course) {
        if (course == 100) {
            return "A++"
        } else if (course >= 95) {
            return "A+"
        } else if (course >= 90) {
            return "A"
        } else if (course >= 85) {
            return "B+"
        } else if (course >= 80) {
            return "B"
        } else if (course >= 75) {
            return "C+"
        } else if (course >= 70) {
            return "C"
        } else if (course >= 65) {
            return "D+"
        } else if (course >= 60) {
            return "D"
        } else if (course >= 50) {
            return "E"
        } else if (course == 0){
            return "/"
        } else {
            return ""
        }
    }
  },
};
</script>

<style lang="less" scoped>
.subject-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .title-auto{
        margin: 20px;
    }
}

table {
  width: 700px;
  border-collapse: collapse;
  margin-bottom: 10px;
}
th,
td {
  padding: 8px;
  text-align: center;
}
.course-table {
  margin-bottom: 10px;
  border-color: #fda947;
}
.course-name {
  width: 50px; /* 固定课程名称列的宽度 */
}
.course-bottom{
    text-align: center;
    line-height: 40px;
}

th, td {
  border: 1px solid #fda947; /* 你可以根据需要更改颜色 */
}

</style>