<template>
  <div :class="['content', bagc]">
    <img src="../../assets/term/term_pro_mark.png" alt="#" class="mark-img" />
    <span class="mark-title">{{ title }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: "",
  },
  computed: {
    ...mapState(["themType"]),
    bagc() {
      if (typeof this.themType == "undefined") {
        return "bgc-green";
      } else if (this.themType == "green") {
        return "bgc-green";
      } else if (this.themType == "blue") {
        return "bgc-blue";
      } else {
        return "bgc-organe";
      }
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 226px;
  height: 48px;

  .mark-img {
    margin-right: 20px;
  }
  .mark-title {
    font-size: 24px;
    line-height: 29px;
    color: #fff;
  }
}

.bgc-green {
  background: linear-gradient(90deg, #329851 0%, #2fa984 100%);
}

.bgc-blue {
  background: linear-gradient(90deg, #47a1fc 0%, #4468b7 100%);
}

.bgc-organe {
  background: linear-gradient(238deg, #ff9a72 0%, #fd6427 63%, #fda947 100%);
}
</style>
