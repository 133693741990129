<template>
  <div class="contanier">
    <!-- 荣誉时刻 -->
    <div class="title-pack">
      <project-name title="荣誉时刻" />
    </div>
    <div v-if="prize.length > 0" class="project-table">
      <!-- 荣誉列表 -->
      <div class="section-title">
        <!-- <div class="date-title first-type">获奖时间</div> -->
        <div class="date-title second-type">所获奖项</div>
        <div class="date-title three-type">奖项级别</div>
      </div>
      <div class="section-rows" v-for="(item, index) in prize" :key="index">
        <div class="row-index">{{ index + 1 }}</div>
        <div class="row-content">
          <!-- <div class="date-title first-type">
            {{ item.GetDate.slice(0, 10) }}
          </div> -->
          <div class="date-title second-type">{{ item.Title }}</div>
          <div class="date-title three-type">{{ item.Jiebie }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectName from "./ProjectName.vue";
export default {
  components: {
    ProjectName,
  },
  props: {
    prize: [],
  },
  data() {
    return {
      rows: [...new Array(6)].map((_, index) => index + 1),
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.contanier {
  position: relative;

  .title-pack {
    padding-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-table {
    .section-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 500px;
      margin-left: 47px;
      padding: 0 20px;
      box-sizing: border-box;

      .date-title {
        font-size: 19px;
        font-weight: 500;
      }
    }

    .section-rows {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;

      .row-index {
        width: 30px;
        height: 30px;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
        border-radius: 15px;
        background: linear-gradient(to right, #ecbabd, #f1d8e4);
      }

      .row-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 30px;
        width: 500px;
        background: linear-gradient(to right, #ecbabd, #f1d8e4);
        border-radius: 15px;
        font-weight: 500;
        margin-left: 17px;
        padding: 0 20px;
        box-sizing: border-box;

        .date-title {
          font-size: 16px;
          line-height: 18px;
          padding: 4px 0;
        }
      }
    }

    .first-type {
      flex: 0.2;
    }

    .second-type {
      flex: 0.8;
    }

    .three-type {
      flex: 0.2;
      text-align: center;
    }
  }
}
</style>
