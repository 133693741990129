<template>
  <div class="home-main">
    <el-container
      v-if="stuMap.length"
      id="main-container"
      :class="['main-container', showToggle ? 'main-show' : null]"
    >
      <el-header
        :class="['main-header', titleBg]"
        style="width: 20vw; height: 15vh"
      >
        <div class="header-title">素质报告系统</div>
      </el-header>
      <el-aside
        class="term-aside"
        width="20vw"
        style="background-color: rgb(238, 241, 246); height: 100vh"
      >
        <el-menu :default-openeds="['0']">
          <el-menu-item-group v-for="(items, index) in stuList" :key="index">
            <template slot="title">{{
              `${items[0].student.StuName}(${items[0].student.ClassFullName})`
            }}</template>
            <el-menu-item
              v-for="(obj, idx) in items"
              :key="idx"
              @click="changeYears(obj)"
              >{{ obj.AcdemicYearFull }}</el-menu-item
            >
          </el-menu-item-group>
        </el-menu>
        <el-button
          type="button"
          id="bnt_close"
          class="btn btn-sm btn-success"
          @click="phoneChangeYear"
        >
          退出
        </el-button>
      </el-aside>
    </el-container>
    <div class="print-main">
      <div id="printer">
        <!-- <el-select
          id="bnt_select"
          v-model="value"
          placeholder="请选择主题"
          style="width: 110px"
          @change="changeTheme"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->

        <el-button
          type="button"
          id="bnt_print"
          class="btn btn-sm btn-success"
          onclick="window.print();"
        >
          打印
        </el-button>
        <el-button
          type="button"
          id="bnt_change"
          class="btn btn-sm btn-success"
          @click="phoneChangeYear"
        >
          切换学期
        </el-button>
      </div>
      <term-start :bgimg="termInfo.page1Image" :headimg="termInfo.HeadImage" :info="termInfo">
        <!-- 封面 -->
      </term-start>
      <term-message :bgimage="termInfo.page2Image">
        <!-- 校长寄语 -->
      </term-message>
      <term-list :list="list" :bgimage="termInfo.page3Image">
        <!-- 目录 -->
      </term-list>

      <!-- 单期报告 -->
      <term-main :hiddenMark="false" :SchoolId="termInfo.SchoolId">
        <!-- 首页 -->
        <term-info v-if="termInfo.SchoolId != '50'" :Suyang="isShow.Suyang" :info="termInfo" />
        <term-info-50 v-else :Suyang="isShow.Suyang" :info="termInfo" />
      </term-main>
      <term-main v-if="isShow.DePage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 品德发展  -->
        <MoralDevelopment
          :evals="termInfo.ReportEval"
          :dePhotos="termInfo.ReportPhotos.DePhotos"
        />
      </term-main>
      
      <term-main v-if="(isShow.ZhiPage || View == '1') && termInfo.SchoolId == '50'" :SchoolId="termInfo.SchoolId">
        <!-- 学科成绩 schoolid 为50 -->
        <SubjectTable
          :courseLevelList="termInfo.Suyang.courseLevelList"
          :OpenLession="termInfo.OpenLession"
          :ScoreShetuan="termInfo.ScoreShetuan"
        />
      </term-main>
      <term-main v-else-if="isShow.ZhiPage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 学科成绩 -->
        <SubjectAchie
          :courseLevelList="termInfo.Suyang.courseLevelList"
          :OpenLession="termInfo.OpenLession"
          :ScoreShetuan="termInfo.ScoreShetuan"
        />
      </term-main>
      <term-main v-if="isShow.TiPage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 体质健康 -->
        <term-body
          :studentHealth="termInfo.StudentHealth"
          :staminaTest="termInfo.StaminaTest"
          :ReportEvalSum="termInfo.ReportEvalSum"
          :Schoolid="termInfo.SchoolId"
        />
      </term-main>
      <term-main v-if="isShow.XinliPage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 心理健康 -->
        <MentalHealth
          :evals="termInfo.ReportEval"
          :XinliCepingResult="termInfo.XinliCepingResult"
          :XinliCeping="isShow.XinliCeping"
        />
      </term-main>
      <term-main v-if="isShow.MeiPage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 艺术素养 -->
        <ArtisticAccomp
          :evals="termInfo.ReportEval"
          :ReportPhotos="termInfo.ReportPhotos"
          :ReportEvalSum="termInfo.ReportEvalSum"
        />
      </term-main>
      <term-main v-if="isShow.LaoPage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 劳动实践 -->
        <LaborPractice
          :evals="termInfo.ReportEval"
          :dePhotos="termInfo.ReportPhotos.LaoPhotos"
        />
      </term-main>
      <term-main v-if="isShow.KeChuangPage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 科创实践 -->
        <SciencePractice
          :evals="termInfo.ReportEval"
          :kePhotos="termInfo.ReportPhotos.KechuangPhotos"
        />
      </term-main>
      <term-main v-if="(isShow.TePage || View == '1') && termInfo.SchoolId != '50'" :SchoolId="termInfo.SchoolId">
        <!-- 个性特长 -->
        <IndividualStrength :tePhotos="termInfo.ReportPhotos.TePhotos"/>
      </term-main>
      <term-main v-if="isShow.JiangPage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 荣誉时刻 -->
        <term-others
          :Jiang="isShow.Jiang"
          :Zhang="isShow.Zhang"
          :getZhang="termInfo.GetZhang[0]"
          :zhangPhotos="termInfo.ReportPhotos.ZhangPhotos"
          :prize="termInfo.Prize"
          :SchoolId="termInfo.SchoolId"
        />
      </term-main>
      <term-main v-if="isShow.PingPage || View == '1'" :SchoolId="termInfo.SchoolId">
        <!-- 数字印象 -->
        <term-last
          :teacherComment="termInfo.TeacherComment"
          :stuName="termInfo.StuName"
          :terachName="termInfo.TeacherName"
          :cloudWords="termInfo.CloudWords"
          :schoolLogo="termInfo.Logo"
          :shuzhi="isShow.Shuzi"
          :pingyu="isShow.Pingyu"
          :SchoolId="termInfo.SchoolId"
          :classs="termInfo.ReportPhotos.ClassPhoto"
        />
      </term-main>
      <!-- 50学校将个性特长换到教师评语之后 -->
      <term-main v-if="(isShow.TePage || View == '1') && termInfo.SchoolId == '50'" :SchoolId="termInfo.SchoolId">
        <!-- 个性特长 -->
        <IndividualStrength :tePhotos="termInfo.ReportPhotos.TePhotos" :Schoolid="termInfo.SchoolId"/>
      </term-main>
      <term-song v-if="termInfo.page4view" :bgimage="termInfo.page4Image">
        <!-- 校歌 -->
      </term-song>
      <term-end :bgimage="termInfo.page5Image">
        <!-- 封底 -->
      </term-end>
    </div>
  </div>
</template>

<script>
import TermMain from "../components/TermMain.vue";
import TermInfo from "../termpapers/termInfo.vue";
import TermInfo50 from "../termpapers/termInfo50.vue";
import TermBody from "../termpapers/termBody.vue";
import TermLast from "../termpapers/termLast.vue";
import TermOthers from "../termpapers/termOthers.vue";
import MoralDevelopment from "./MoralDevelopment.vue";
import LaborPractice from "./LaborPractice.vue";
import SubjectAchie from "./SubjectAchie.vue";
import SubjectTable from "./SubjectTable.vue";
import ArtisticAccomp from "./ArtisticAccomp.vue";
import MentalHealth from "./MentalHealth.vue";
import SciencePractice from "./SciencePractice.vue";
import IndividualStrength from "./IndividualStrength.vue";

import TermStart from "./termStart.vue";
import TermList from "./termList.vue";
import TermMessage from "./termMessage.vue";
import TermSong from "./termSong.vue";
import TermEnd from "./termEnd.vue";

import { getTerm } from "@/api/term";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    TermMain,
    TermInfo,
    MoralDevelopment,
    TermBody,
    TermLast,
    TermOthers,
    LaborPractice,
    SubjectAchie,
    ArtisticAccomp,
    MentalHealth,
    SciencePractice,
    IndividualStrength,
    TermStart,
    TermList,
    TermMessage,
    TermSong,
    TermEnd,
    SubjectTable,
    TermInfo50
  },
  computed: {
    ...mapState(["stuMap"]),
    isShow() {
      return this.termInfo.IsShow;
    },
    stuList() {
      let datas = JSON.parse(this.stuMap);
      var students = [];
      var keys = [];
      datas.forEach((item) => {
        item.forEach((obj) => {
          let key = `${obj.student.StuName}(${obj.student.ClassFullName})`;
          let index = keys.indexOf(key);
          console.log(index);
          if (index >= 0) {
            var items = students[index];
            items.push(obj);
          } else {
            keys.push(key);
            students.push([obj]);
          }
        });
      });

      return students;
    },
  },
  data() {
    return {
      options: [
        {
          value: "green",
          label: "绿色主题",
        },
        {
          value: "blue",
          label: "蓝色主题",
        },
        {
          value: "orange",
          label: "橙色主题",
        },
      ],
      value: "orange",
      titleBg: this.getOrangeColor(),
      termInfo: {},
      View: "0",
      showToggle: false,
      list:[]
    };
  },
  created() {
    if (typeof this.$route.query.View != "undefined") {
      this.View = this.$route.query.View;
    }
    var AcdemicYear = "2023-2024";
    var Semester = "1";
    if (typeof this.$route.query.AcdemicYear != "undefined") {
      AcdemicYear = this.$route.query.AcdemicYear;
    }
    if (typeof this.$route.query.Semester != "undefined") {
      Semester = this.$route.query.Semester;
    }
    this.getTermInfo({ AcdemicYear, Semester });
  },
  mounted() {
    if (typeof this.$route.query.color == "undefined") {
      this.value = "orange";
      this.titleBg = this.getOrangeColor();
    } else if (this.$route.query.color == "green") {
      this.value = "green";
      this.titleBg = "bgc-green";
    } else if (this.$route.query.color == "blue") {
      this.value = "blue";
      this.titleBg = "bgc-blue";
    } else {
      this.value = "orange";
      this.titleBg = this.getOrangeColor();
    }
    this.changeThemType({ type: this.value });
    setTimeout(() => {
    this.changeTheme()
    }, 500);
  },
  methods: {
    ...mapMutations(["changeThemType"]),
    changeTheme() {
      this.changeThemType({ type: this.value });
      if (this.value == "green") {
        this.titleBg = "bgc-green";
      } else if (this.value == "blue") {
        this.titleBg = "bgc-blue";
      } else {
        this.titleBg = this.getOrangeColor();
      }
    },
    getTermInfo(arg) {
      if (typeof this.$route.query.StuNo == "undefined") {
        alert("请输入学号");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      getTerm({
        StuNo: this.$route.query.StuNo,
        AcdemicYear: arg.AcdemicYear,
        Semester: arg.Semester,
        View: this.View,
      }).then((res) => {
        loading.close();
        if (res.code == 0) {
          this.termInfo = res.data;
          document.title =
            this.termInfo.StuName + this.termInfo.AcdemicYearFull + "素质报告";
            this.getList();
        }
      });
    },
    changeYears(arg) {
      this.getTermInfo({
        AcdemicYear: arg.AcdemicYear,
        Semester: arg.Semester,
      });
    },
    phoneChangeYear() {
      this.showToggle = !this.showToggle;
    },
    getList() {
      // 计算目录
      if (this.isShow.Suyang) {
        this.list.push("学科素养")
      }
      if (this.isShow.DePage || this.View == '1') {
        this.list.push("品德发展")
      }
      if (this.isShow.ZhiPage || this.View == '1') {
        this.list.push("学科成绩")
      }
      if (this.isShow.TiPage || this.View == '1') {
        this.list.push("体质健康")
      }
      if (this.isShow.XinliPage || this.View == '1') {
        this.list.push("心理健康")
      }
      if (this.isShow.MeiPage || this.View == '1') {
        this.list.push("艺术素养")
      }
      if (this.isShow.LaoPage || this.View == '1') {
        this.list.push("劳动实践")
      }
      if (this.isShow.KeChuangPage || this.View == '1') {
        this.list.push("科创实践")
      }
      if ((this.isShow.TePage || this.View == '1') && this.termInfo.SchoolId != '50') {
        this.list.push("个性特长")
      }
      if (this.isShow.JiangPage || this.View == '1') {
        this.list.push("荣誉时刻")
      }
      if (this.isShow.Pingyu && this.termInfo.TeacherComment.length > 0 || this.View == '1') {
        this.list.push("教师评语")
      }
      if ((this.isShow.TePage || this.View == '1') && this.termInfo.SchoolId == '50') {
        this.list.push("自我成长")
      }
    },
    getOrangeColor() {
      return this.termInfo?.SchoolId == '50' ? 'bgc-orangr-color' : 'bgc-orange'
    }
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .main-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(238, 241, 246);
    border-bottom: 1px solid rgb(238, 241, 246);

    .header-title {
      font-family: "Times New Roman", Times, serif;
      font-size: 40px;
      font-weight: 700;
      color: white;
    }
  }

  #bnt_close {
    display: none;
  }
  #bnt_change {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  #bnt_close {
    display: inline-block !important;
  }

  #bnt_change {
    display: inline-block !important;
  }

  .main-show {
    z-index: 999;
  }

  .main-container {
    width: 50vw !important;

    .main-header {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgb(238, 241, 246);
      border-bottom: 1px solid rgb(238, 241, 246);
      width: 50vw !important;
    }
    .term-aside {
      width: 50vw !important;
    }
  }
}

.main-container {
  position: fixed;
}

.bgc-green {
  background: linear-gradient(90deg, #329851 0%, #2fa984 100%);
}

.bgc-blue {
  background: linear-gradient(90deg, #47a1fc 0%, #4468b7 100%);
}

.bgc-orange {
  background: linear-gradient(238deg, #ff9a72 0%, #fd6427 63%, #fda947 100%);
}

.bgc-orangr-color {
  background: #ed6c00;
}
</style>
