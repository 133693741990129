<template>
  <div class="page" id="page8">
    <span class="page-title">2.达标成绩</span>
    <table class="mark-table">
      <tr>
        <th>年级</th>
        <th v-for="item in tables" :key="item">{{ item }}</th>
      </tr>
      <tr>
        <td>跳绳</td>
        <td v-for="(index, item) in tables" :key="item">优秀</td>
      </tr>
      <tr>
        <td>坐位体前屈</td>
        <td v-for="(index, item) in tables" :key="item">优秀</td>
      </tr>
      <tr>
        <td>50M跑</td>
        <td v-for="(index, item) in tables" :key="item">优秀</td>
      </tr>
      <tr>
        <td>仰卧起坐</td>
        <td v-for="(index, item) in tables" :key="item">优秀</td>
      </tr>
    </table>
    <span class="page-title-section">3.达标发展</span>
    <div class="charts-list">
      <!-- 图表列表 -->
      <div class="charts-item charts-item-first">
        <LineChartsItem></LineChartsItem>
      </div>
      <div class="charts-item charts-item-second">
        <LineChartsItem></LineChartsItem>
      </div>
      <div class="charts-item charts-item-three">
        <LineChartsItem></LineChartsItem>
      </div>
      <div class="charts-item charts-item-four">
        <LineChartsItem></LineChartsItem>
      </div>
    </div>
  </div>
</template>
    
  <script>
import LineChartsItem from "@/components/LineChartsItem.vue";
export default {
  name: "Home",
  components: {
    LineChartsItem,
  },
  data() {
    return {
      echarts: [1, 2, 3, 4],
      tables: ["1年级", "2年级", "3年级", "4年级", "5年级", "6年级"],
    };
  },
};
</script>
    
  <style lang="less" scoped>
#page8 {
  background-image: url("../assets/page8_bg.png");
  background-size: cover;
  overflow: hidden;
  
  .page-title {
    display: block;
    margin-top: 103px;
    margin-left: 57px;
    font-size: 16px;
    line-height: 16px;
  }

  .mark-table {
    background-color: #eee;
    margin-top: 18px;
    margin-left: 56px;
    text-align: center;
    border-collapse: collapse;

    th,
    td {
      border: #ddd 1px solid;
      width: 97px;
      box-sizing: border-box;
    }

    th {
      height: 53px;
    }

    td {
      height: 56px;
    }
  }

  .page-title-section {
    display: block;
    margin-top: 27px;
    margin-left: 57px;
    font-size: 16px;
  }

  .charts-list {
    // 下半截的图表
    width: 678px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 23px auto;

    .charts-item-first {
      background-color: darkcyan;
    }

    .charts-item-second {
      background-color: gold;
    }

    .charts-item-three {
      margin-top: 10px;
      background-color: yellowgreen;
    }

    .charts-item-four {
      margin-top: 10px;
      background-color: peru;
    }

    .charts-item {
      width: 50%;
      height: 250px;
    }
  }
}
</style>
    