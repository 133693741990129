import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import TermLogin from "../termpapers/Login.vue"
import TermHome from "../termpapers/TermHome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    // component: Home,
    redirect: "/termLogin",
  },
  {
    path: "/termHome",
    name: "termHome",
    component: TermHome,
  },
  {
    path: "/termLogin",
    name: "termLogin",
    component: TermLogin,
  }
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
