<template>
  <div class="page" id="page5">
    <div class="page-hd">
      <div class="hd-part">
        <img class="hd-img" src="../assets/page5_hd.png" alt="#" />
      </div>
      <div class="hd-section">2.荣誉勋章</div>
    </div>
    <div class="page-bd">
      <div class="part-list">
        <!-- 数量列表 -->
        <div class="part-one">一年级<br />获得3枚</div>
      </div>
      <!-- 勋章详情 -->
      <div class="xun-zhangs">
          <div class="xun-row">
            <div class="xun-img"></div>
            <span class="xun-title">孝敬章</span>
          </div>
          <div class="xun-row">
            <div class="xun-img"></div>
            <span class="xun-title">孝敬章</span>
          </div>
          <div class="xun-row">
            <div class="xun-img"></div>
            <span class="xun-title">孝敬章</span>
          </div>
          <div class="xun-row">
            <div class="xun-img"></div>
            <span class="xun-title">孝敬章</span>
          </div>
          <div class="xun-row">
            <div class="xun-img"></div>
            <span class="xun-title">孝敬章</span>
          </div>
        </div>
    </div>
    <img src="../assets/page5_bottom_bg.png" alt="#" class="bottom-bg">
  </div>
</template>
    
<script>
export default {
  name: "Home",
  components: {},
};
</script>
    
<style lang="less" scoped>
#page5 {
  background-color: #eee;

  .page-hd {
    display: flex;
    justify-content: flex-start;
    height: 130px;
    margin: 0 66px;

    .hd-part {
      width: 73px;
      height: 100%;
      background-color: #fff;

      .hd-img {
        width: 100%;
        height: 100%;
      }
    }

    .hd-section {
      height: 100%;
      line-height: 3cm;
      text-align: center;
      margin-left: 86px;
      font-weight: 700;
      font-size: 16px;
    }
  }

  .page-bd {
    display: flex;
    justify-content: flex-start;
    margin: 0 66px;

    .part-list {
      height: 160px;
      width: 73px;
      background-color: #fff;

      .part-one {
        width: 100%;
        text-align: center;
        margin-top: 33px;
      }

    }

    .xun-zhangs {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        height: 160px;
        margin-left: 40px;
        flex: 1;

        .xun-row {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .xun-img {
            background-color: palevioletred;
            border-radius: 55px;
            height: 76px;
            width: 76px;
          }

          .xun-title {
            margin-top: 13px;
            font-size: 12px;
          }
        }
      }
  }

  .bottom-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 499px;
    height: 616px;
  }

}
</style>
    