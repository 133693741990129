<template>
    <div class="container">
        <div ref="parent" class="page" id="page15">
            <!-- 第一页 -->
            <span class="page-title">七、教师评语</span>
            <div ref="element" class="grade-row" v-for="item in items" :key="item">
                <!-- 教师评语 -->
                <div class="grade-level">{{ item }}年级上期</div>
                <div class="grade-desc">
                    何俊杰，看到你安静、认真阅读的时候，老师在心里悄悄地欣喜；看到你默默地观察、悄悄地思索的时候老师为你的进步感到高兴！要是你能在做事情的时候提高自己的速度和效率，就更棒了！</div>
                <div class="grade-teacher"> ——张老师 </div>
            </div>
        </div>
        <div ref="parent2" class="page" id="page16">
            <!-- 第二页 -->
            <div ref="element2" class="grade-row section-center" v-for="item in movedItems1" :key="item">
                <!-- 教师评语 -->
                <div class="grade-level">{{ item }}年级上期</div>
                <div class="grade-desc">
                    何俊杰，看到你安静、认真阅读的时候，老师在心里悄悄地欣喜；看到你默默地观察、悄悄地思索的时候老师为你的进步感到高兴！要是你能在做事情的时候提高自己的速度和效率，就更棒了！</div>
                <div class="grade-teacher"> ——张老师 </div>
            </div>
        </div>
        <div class="page" id="page17">
            <!-- 第三页 -->
            <div ref="element3" class="grade-row section-center" v-for="item in movedItems2" :key="item">
                <!-- 教师评语 -->
                <div class="grade-level">{{ item }}年级上期</div>
                <div class="grade-desc">
                    何俊杰，看到你安静、认真阅读的时候，老师在心里悄悄地欣喜；看到你默默地观察、悄悄地思索的时候老师为你的进步感到高兴！要是你能在做事情的时候提高自己的速度和效率，就更棒了！</div>
                <div class="grade-teacher"> ——张老师 </div>
            </div>
            <div class="teacher-item">
                <!-- 班主任签名 -->
                <span class="mark-title">班主任签名：</span>
                <img class="teacher-img" src="../assets/page15_teacher_name.png" alt="#">
            </div>
            <span class="bottom-desc">豆豆们，去用心成长，追逐梦想……</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [...new Array(12)].map((_, index) => index + 1),
            movedItems1: [],
            movedItems2: [],
        }
    },
    mounted() {
        this.checkAndMoveItems();
        window.addEventListener('resize', this.checkAndMoveItems);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkAndMoveItems);
    },
    methods: {
        checkAndMoveItems() {
            const parent = this.$refs.parent;
            const elements = this.$refs.element;

            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                const parentRect = parent.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();

                const distanceToBottom = parentRect.bottom - elementRect.bottom;

                if (distanceToBottom < 100) {
                    if (element.parentElement === parent) {
                        const movedItem = this.items.splice(i, this.items.length - i);
                        this.movedItems1 = movedItem;
                        this.$nextTick(() => {
                            this.checkAndMoveTwoItems();
                        })
                        return
                    }
                }
            }
        },
        checkAndMoveTwoItems() {
            const parent2 = this.$refs.parent2;
            const elements = this.$refs.element2;
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                const parent2Rect = parent2.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();

                const distanceToBottom = parent2Rect.bottom - elementRect.bottom;

                if (distanceToBottom < 100) {
                    if (element.parentElement === parent2) {
                        const movedItem = this.movedItems1.splice(i, 1)[0];
                        this.movedItems2.push(movedItem);
                        i--;
                        if (i == this.movedItems1.length - 1) {
                            return
                        }
                        console.log("distanceToBottom1: " + this.movedItems1 + "::movedItems2: " + this.movedItems2);
                    }
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
#page15 {
    background-image: url("../assets/page6_bg.png");
    background-size: cover;
    padding-left: 59px;
    overflow: hidden;

    .page-title {
        font-size: 37px;
        line-height: 37px;
        font-weight: 700;
        color: #30744c;
        margin-top: 127px;
        margin-bottom: 83px;
        display: block;
    }

}

#page16 {
    background-image: url("../assets/page8_bg.png");
    background-size: cover;
    padding: 116px 78px;
    overflow: hidden;
    box-sizing: border-box;


}

#page17 {
    background-image: url("../assets/page8_bg.png");
    background-size: cover;
    padding: 116px 78px;
    overflow: hidden;
    box-sizing: border-box;

    .teacher-item {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 104px;

        .mark-title {
            color: #231815;
            font-size: 16px;
            font-weight: 400;
        }

        .teacher-img {
            width: 108px;
            height: 52px;
        }

    }

    .bottom-desc {
        position: absolute;
        left: 50%;
        bottom: 89px;
        transform: translateX(-50%);
        color: #30744C;
        font-size: 16px;
        line-height: 16px;
    }

}

.section-center {
    width: 637px !important;
}

.grade-row {
    position: relative;
    margin-top: 34px;
    width: 550px;

    .grade-level {
        font-size: 16px;
        line-height: 16px;
    }

    .grade-desc {
        margin-top: 39px;
        font-size: 15px;
    }

    .grade-teacher {
        position: absolute;
        right: 0;
        margin-top: 10px;
        font-size: 15px;
    }
}
</style>