<template>
  <div class="page" id="page10">
    <div class="section-one">三年级代表作品</div>
    <div class="sectoin-rows">
      <!-- 作品列表 -->
      <div class="row-item" v-for="item in rows" :key="item">
        <div class="row-img"></div>
        <span class="row-title">音乐作品</span>
      </div>
    </div>

    <div class="section-one">四年级代表作品</div>
    <div class="sectoin-rows">
      <!-- 作品列表 -->
      <div class="row-item" v-for="item in rows" :key="item">
        <div class="row-img"></div>
        <span class="row-title">音乐作品</span>
      </div>
    </div>

    <div class="section-one">五年级代表作品</div>
    <div class="sectoin-rows">
      <!-- 作品列表 -->
      <div class="row-item" v-for="item in rows" :key="item">
        <div class="row-img"></div>
        <span class="row-title">音乐作品</span>
      </div>
    </div>

    <div class="section-one">六年级代表作品</div>
    <div class="sectoin-rows">
      <!-- 作品列表 -->
      <div class="row-item" v-for="item in rows" :key="item">
        <div class="row-img"></div>
        <span class="row-title">音乐作品</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rows: [1, 2, 3, 4],
    };
  },
};
</script>

<style lang="less" scoped>
#page10 {
  background-image: url("../assets/page8_bg.png");
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 106px;
  padding-left: 68px;

  .section-one {
    margin-top: 30px;
    line-height: 13px;
    font-size: 13px;
  }

  .sectoin-rows {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .row-item {
      width: 159px;
      height: 151px;
      border: #999 1px solid;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-top: 26px;
      margin-right: 6px;
      .row-img {
        width: 93px;
        height: 93px;
        background-color: #30744c;
        margin-top: 8px;
      }

      .row-title {
        margin-top: 16px;
        font-size: 11px;
        color: #3e3a39;
      }
    }
  }
}
</style>