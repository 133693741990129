import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'

//创建 axios 实例
const request = axios.create({
  // api 请求默认前置
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL:'api/',
  baseURL:'https://smartcompus.junmao.mobi/',
  timeout: 15000 //请求超时时间
})

const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      
    }
    
  }
  return Promise.reject(error)
}

//响应拦截器
request.interceptors.response.use((response) => {
  if (response.data.code != 200) {
    
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request;

export {
  installer as VueAxios,
  request as axios
}