<template>
  <div class="page" id="page1">
    <img src="../assets/page1_logo.png" alt="#" class="school-logo" />
    <div class="stu-info">
      学生：何俊杰 <br />学号：25 <br />班级：2017年级1班 <br />班主任：张敏
    </div>
    <span class="bottom-tip">为党育人 为国育才</span>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style lang="less" scoped>
#page1 {
  background-image: url("../assets/page1_screen_bg.png");
  background-size: cover;
  background-color: green;
  position: relative;
  color: white;

  .school-logo {
    margin-top: 112px;
    margin-left: 121px;
    width: 228px;
    height: 62px;
  }
  .stu-info {
    position: absolute;
    left: 121px;
    bottom: 189px;
    font-size: 20px;
    line-height: 40px;
  }

  .bottom-tip {
    position: absolute;
    bottom: 55px;
    margin: 0 auto;
  }
}
</style>
