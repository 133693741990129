<template>
  <div class="last-content">
    <!-- <img v-if="SchoolId == '50'" src="../assets/term/term50_mark.png" alt="#" class="page-bgimg"> -->
    <!-- 数字印象/教师评语 -->
    <div v-if="shuzhi && SchoolId != '50'" class="chart-content">
      <!-- 数字印象 -->
      <div class="title-pack">
        <project-name title="数字印象" />
      </div>
      <!--  数字印象 图表 -->
      <div v-if="cloudWords.length" id="count-chart" class="count-chart"></div>
    </div>
    <div v-if="pingyu" class="teacher-remark">
      <!-- 教师评语 -->
      <div class="title-pack">
        <project-name title="教师评语" />
      </div>
      <div v-if="teacherComment.length">
        <div class="stu-name">{{ stuName }}同学：</div>
        <p class="remark-desc">
          {{ teacherComment }}
        </p>
        <div class="teacher-name">— — 班主任 {{ terachName.slice(0,1) }}老师</div>
        <!-- <img :src="schoolLogo" alt="#" class="bg-mark" /> -->
      </div>
    </div>
    <class-photo v-if="SchoolId == '50'" :zhangPhotos="classs">
    </class-photo>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-wordcloud";
import ProjectName from "../components/term/ProjectName.vue";
import ClassPhoto from "../components/term/ClassPhoto.vue";
export default {
  components: {
    ProjectName,
    ClassPhoto
  },
  props: {
    stuName: "",
    terachName: "",
    teacherComment: "",
    cloudWords: [],
    schoolLogo: "",
    shuzhi: true,
    pingyu: true,
    SchoolId: "",
    classs:[]
  },
  data() {
    return {};
  },
  watch: {
    cloudWords() {
      this.updateEchart();
    },
  },
  mounted() {
    this.updateEchart();
  },
  methods: {
    updateEchart() {
      if (this.cloudWords.length > 0) {
        var chartData = this.cloudWords.map((item) => {
          return { name: item.Item1, value: item.Item2 };
        });
        var chart = echarts.init(document.getElementById("count-chart"));
        chart.setOption({
          series: [
            {
              type: "wordCloud",
              shape: "circle",
              keepAspect: false,
              // maskImage: maskImage,
              left: "center",
              top: "center",
              width: "100%",
              height: "100%",
              right: null,
              bottom: null,
              sizeRange: [14, 93],
              rotationRange: [-90, 90],
              rotationStep: 90,
              gridSize: 8,
              drawOutOfBound: false,
              shrinkToFit: false,
              layoutAnimation: true,
              textStyle: {
                fontFamily: "sans-serif",
                fontWeight: "bold",
                color: function () {
                  return (
                    "rgb(" +
                    [
                      Math.round(Math.random() * 160),
                      Math.round(Math.random() * 160),
                      Math.round(Math.random() * 160),
                    ].join(",") +
                    ")"
                  );
                },
              },
              data: chartData,
            },
          ],
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.last-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 13px;
  background-color: #f4f6f5;
  width: 722px;
  height: 1000px;
  margin: 0 auto;
  position: relative;

  .chart-content {
    .count-chart {
      margin: auto;
      background-image: url(../assets/page4_chart_bg.png);
      background-size: 284px 223px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 382px;
      width: 644px;
    }
  }

  .teacher-remark {
    position: relative;
    padding: 0 32px;
    font-size: 19px;
    line-height: 32px;

    .remark-desc {
      text-indent: 2em;
      margin: 0;
    }

    .teacher-name {
      position: absolute;
      right: 32px;
    }

    .bg-mark {
      width: 106px;
      height: 124px;
      margin-left: 50%;
      transform: translateX(-50%);
      object-fit: contain;
      opacity: 0.5;
    }
  }

  .title-pack {
    padding: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-bgimg{
    width: 200px;
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
