<template>
  <!-- 个性特长 -->
  <div class="ind-container">
    <project-name class="page-title" :title="Schoolid == '50' ? '自我成长' : '个性特长'" />
    <p v-if="Schoolid != '50'" class="dev-desc">
      <!-- 个性描述评语 -->
      每个小学生都是独一无二、充满潜力的个体，他们在不同的领域都有可能展现出自己的特长。特长不仅仅是某项技能上的突出表现，还可以体现在学术、艺术、体育等多个方面。
    </p>
    <p v-else class="dev-50">
      <!-- 个性描述评语 -->
      成长的道路上，我收获了知识的力量，也明白了坚持的意义。我为每一次的成功而雀跃，也从每一次的失败中积攒力量。我期待在未来的日子里，继续探索，继续成长，直到找到那个最真实、最强大的自己。<p class="dev-50-two">本学期，你有哪些收获？哪些疑惑？请梳理记录下来，整装再出发！</p>
    </p>
    <div class="imgs-main">
      <div class="img-rows" v-for="(item, index) in tePhotos" :key="index">
        <img :src="item.Photo" alt="#" class="item-img" />
        <span class="item-name">{{ item.Title }}</span>
      </div>
    </div>
    <div v-if="Schoolid == '50'" class="stu-draw"></div>
  </div>
</template>

<script>
import ProjectName from "../components/term/ProjectName.vue";
export default {
  components: {
    ProjectName,
  },
  props: {
    tePhotos: [],
    Schoolid: "",
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.ind-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-title {
    margin: 24px auto;
  }

  .dev-desc {
    margin: 0 24px;
    margin-top: 16px;
    color: #3e3a39;
    font-size: 16px;
    text-indent: 2em;
  }

  .dev-50 {
    margin: 0 24px;
    margin-top: 16px;
    color: #3e3a39;
    font-size: 16px;
    line-height: 30px;
    text-indent: 2em;
  }

  .dev-50-two {
    margin: 0 24px;
    color: #3e3a39;
    font-size: 16px;
    line-height: 30px;
    text-indent: 2em;
  }

  .imgs-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 24px;
    margin-top: 24px;

    .img-rows {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .item-img {
        min-width: 220px;
        height: 160px;
        border-radius: 12px;
        object-fit: cover;
      }

      &:first-child .item-img {
        width: 674px;
        height: 231px;
      }

      .item-name {
        font-size: 17px;
        text-align: center;
        margin: 17px;
      }
    }
  }

  .stu-draw {
    margin: 0 auto;
    width: 660px;
    height: 300px;
    border: orange 2px solid;
    border-radius: 20px;
  }
}
</style>
