//封装成 Vue 插件
const VueAxios = {
    vm: {},
    install(Vue, instance) {
        if(this.installed) {
            return;
        }
        this.installed = true;

        if(!instance) {
            console.error('你还没有安装aixos');
            return;
        }

        Vue.axios = instance;

        Object.defineProperties(Vue.prototype, {
            axios: {
                get: function get() {
                    return instance;
                }
            },
            $http: {
                get: function get() {
                    return instance;
                }
            }
        })
    }
}

export {
    VueAxios
}