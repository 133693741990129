<template>
  <div class="contanier">
    <!-- 班级照片 -->
    <div class="title-pack">
      <!-- <project-name title="班级照片" /> -->
    </div>
    <div class="medal-content">
      <img :src="zhangPhotos.Photo" alt="#" class="img-zhang" />
      <!-- <span class="zhang-title">{{ zhangPhotos.Title }}</span> -->
    </div>
  </div>
</template>
  
  <script>
import ProjectName from "./ProjectName.vue";
export default {
  components: {
    ProjectName,
  },
  props: {
    zhangPhotos: {},
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
  
  <style lang="less" scoped>
.contanier {
  width: 100%;
  z-index: 3;

  .title-pack {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .medal-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-size: 19px;
    width: 100%;

    .img-zhang {
      margin: 0 auto;
      width: calc(100% - 60px);
      // height: 300px;
      object-fit: contain;
    }

    .zhang-title {
      margin: 0 auto;
    }
  }
}
</style>
  