<template>
    <div class="page" id="page14">
        <span class="page-title">六、荣誉时刻</span>
        <span class="desc-title">获奖项目</span>
        <div class="section-title">
            <div class="date-title first-type">时间</div>
            <div class="date-title second-type">奖项</div>
            <div class="date-title three-type">颁奖单位</div>
            <div class="date-title four-type">级别</div>
        </div>
        <div class="section-rows" v-for="(index, item) in rows" :key="item">
            <div class="row-index">{{index}}</div>
            <div class="row-content">
                <div class="date-title first-type">2020.01.12</div>
                <div class="date-title second-type">编程比赛一等奖</div>
                <div class="date-title three-type">重庆市九龙坡区</div>
                <div class="date-title four-type">区级奖</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
data(){
    return {
        rows:[...new Array(12)].map((_,index)=> index + 1)
    }
}
}
</script>

<style lang="less" scoped>
#page14 {
    background-image: url("../assets/page6_bg.png");
    background-size: cover;
    padding-left: 59px;
    overflow: hidden;

    .page-title {
        font-size: 37px;
        line-height: 37px;
        font-weight: 700;
        color: #30744c;
        margin-top: 127px;
        display: block;
    }

    .desc-title {
        margin-top: 107px;
        margin-left: 57px;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        display: block;
    }

    .section-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 44px;
        width: 500px;
        margin-left: 47px;
        padding: 0 20px;
        box-sizing: border-box;

        .date-title{
            font-size: 13px;
            font-weight: 500;
        }
    }

    .section-rows {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;

        .row-index {
            width: 30px;
            height: 30px;
            font-size: 13px;
            font-weight: 400;
            text-align: center;
            line-height: 30px;
            border-radius: 15px;
            background: linear-gradient(to right, #ECBABD, #F1D8E4);
        }

        .row-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 30px;
            width: 500px;
            background: linear-gradient(to right, #ECBABD, #F1D8E4);
            border-radius: 15px;
            font-weight: 500;
            margin-left: 17px;
            padding: 0 20px;
            box-sizing: border-box;

            .date-title {
                font-size: 13px;
            }


        }
    }

    .first-type {
        flex: 0.2;
    }

    .second-type {
        flex: 0.4;
    }

    .three-type {
        flex: 0.3;
    }

    .four-type {
        flex: 0.1;
    }

}
</style>