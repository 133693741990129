<template>
  <!-- 心理健康 -->
  <div class="mental-container">
    <project-name class="title-auto" title="心理健康" />
    <p class="dev-desc">
      <!-- 心理健康描述评语 -->
      我们不但要有健康的身体，还要有健康的心理，良好的社会适应能力是身心健康的一个重要表现。作为新时代的学生，要积极参与心理健康教育活动，主动了解和关注自己的心理健康状况。以积极的态度面对挫折和困难。学会有效地应对和管理压力，只有拥有良好的心理健康，才能更好地适应学习和生活的各种挑战。
    </p>
    <div v-if="reals.length" class="mental-main">
      <span class="men-title">心理素质</span>
      <div
        v-for="item in reals"
        :key="item.EvalId"
        class="main-sction"
      >
        <TermRate :current="item.Star" />
        <div class="item-desc">
          <span class="row-weidu">{{ item.Weidu }}</span>
          <span class="row-title"> {{ item.Dimension }}</span>
        </div>
      </div>
    </div>
    <div v-if="XinliCeping" class="mental-foot">
      <span class="men-title">心理测评</span>
      <p class="mental-desc">
        {{ XinliCepingResult }}
      </p>
      <div v-if="reals.length < 15" class="foot-imgs">
        <img src="../assets/term/term_xinli_1.png" alt="#" class="img-item" />
        <img
          src="../assets/term/term_xinli_2.png"
          alt="#"
          class="img-item item-center"
        />
        <img src="../assets/term/term_xinli_3.png" alt="#" class="img-item" />
      </div>
    </div>
    <img src="../assets/term/term_men_bg.png" alt="#" class="page-bg" />
  </div>
</template>

<script>
import ProjectName from "../components/term/ProjectName.vue";
import TermRate from "../components/term/TermRate.vue";
export default {
  components: {
    ProjectName,
    TermRate,
  },
  props: {
    evals: [],
    XinliCepingResult: "",
    XinliCeping: {
      default: true,
    },
  },
  data() {
    return {
      reals: [],
    };
  },
  created() {
    this.reals = this.evals
      .filter((item) => item.Tag == "心")
      .sort((a, b) => a.Sort - b.Sort);
  },
};
</script>

<style lang="less" scoped>
.mental-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  box-sizing: border-box;

  .title-auto {
    margin: 0 auto;
    margin-top: 32px;
  }

  .dev-desc {
    margin: 16px 0;
    color: #3e3a39;
    font-size: 16px;
    text-indent: 2em;
  }

  .mental-main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .men-title {
      color: #3e3a39;
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .main-sction {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;

      .item-desc {
        color: #3e3a39;
        font-size: 17px;
        margin-left: 24px;
        line-height: 20px;
      }

      .row-title {
        margin-left: 12px;
      }
    }
  }

  .mental-foot {
    .men-title {
      color: #3e3a39;
      font-size: 17px;
      font-weight: 500;
    }

    .mental-desc {
      color: #3e3a39;
      font-size: 16px;
      text-indent: 2em;
    }
  }

  .foot-imgs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    .img-item {
      width: 202px;
      height: 152px;
      border-radius: 10px;
    }

    .item-center {
      margin: 0 16px;
    }
  }

  .page-bg {
    position: absolute;
    right: 46px;
    top: 289px;
    width: 231px;
    height: 160px;
  }
}
</style>