<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Page1 from "./views/page1.vue";
export default {
  components: {
    Page1,
  },
  data() {
    return {};
  },
  mounted() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store") ) {
        this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    } 

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload",()=>{
        sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    })
  },
};
</script>

<style lang="less">
body {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "微软雅黑";
  font-size: 14px;
  text-align: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.page {
  margin: 10px auto;
  border: 1px solid #9e9e9e;
}

#printer {
  display: block;
  width: auto;
  height: 30px;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 100;
  text-align: center;
}

.print-main {
}

@page {
  size: A4 portrait;
  margin: 0;
}

@media print {
  #bnt_print,
  #bnt_select,
  #bnt_change,
  #main-container {
    display: none;
  }

  .print-main {
    display: block;
  }

  .page {
    page-break-after: avoid;
    margin: initial;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  body {
    -webkit-print-color-adjust: exact;
  }
}

.page {
  width: 21cm;
  height: 29.7cm;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  background-color: #ffffff;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.page_null {
  display: none;
}

#bnt_print {
  margin-left: 16px;
}
</style>
