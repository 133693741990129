<template>
  <div class="page" id="page13">
    <div class="rows-content">
      <!-- 作品列表 -->
      <div class="row-item" v-for="item in rows" :key="item">
        <div class="row-img"></div>
        <span class="row-title">一年级上期</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            rows:[]
        }
    },
    mounted() {
        this.rows = [...new Array(24)].map((_,index) => index + 1)
    }
}
</script>

<style lang="less" scoped>
#page13 {
  background-image: url("../assets/page8_bg.png");
  background-size: cover;
  overflow: hidden;

.rows-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 87px;

    .row-item {
      width: 159px;
      height: 126px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-top: 26px;
      margin-right: 6px;

      .row-img {
        width: 93px;
        height: 93px;
        background-color: #30744c;
        margin-top: 8px;
      }

      .row-title {
        margin-top: 16px;
        font-size: 11px;
        color: #3e3a39;
      }
    }

}

}
</style>