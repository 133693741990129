<template>
  <div class="page" id="page7">
    <span class="page-ttitle">三、身心发展</span>
    <div class="page-content">
      <!-- 中间内容 -->
      <div class="info-left">
        <!-- 左侧信息列表 -->
        <span class="left-title">1.体质健康（分学年检测）</span>
        <div class="left-section">
          <!-- 信息 -->
          <div
            class="left-section-row"
            v-for="(index, item) in leftRows"
            :key="item"
          >
            <div class="row-mark">
              <!-- 左侧标记⭕️ -->
            </div>
            <div class="row-right">
              <!-- 具体信息 -->
              <div class="row-right-top">
                <span class="row-right-top-first">身高(cm) 158 </span>
                <span class="row-right-top-second">评价 中上</span>
              </div>
              <div class="row-right-bottom">
                <span class="row-right-top-first">体重(kg) 58 </span>
                <span class="row-right-top-second">评价 中等</span>
              </div>
            </div>
          </div>
        </div>
        <span class="section-bottom-tip">肺活量体重指数 57.81</span>
        <img
          class="info-left-line"
          src="../assets/page7_left_line.png"
          alt="#"
        />
        <span class="left-bottom">{{ leftBottom }}</span>
        <img
          class="info-left-right"
          src="../assets/page7_left_right.png"
          alt="#"
        />
      </div>
      <div class="charts-right">
        <!-- 右侧图表列表 -->
        <div class="charts-item">
          <LineChartsItem></LineChartsItem>
        </div>
        <div class="charts-item">
          <LineChartsItem></LineChartsItem>
        </div>
        <div class="charts-item">
          <LineChartsItem></LineChartsItem>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import LineChartsItem from "@/components/LineChartsItem.vue";
export default {
  name: "Home",
  data() {
    return {
      leftBottom:
        "注：BMI指数（英文为Body Mass Index，简称BMI），是用体重千克数除以身高米数的平方得出的数字，是国际上常用的衡量人体胖瘦程度以及是否健康的一个标准。正常范围：18.5≤BMI<23",
      leftRows: [1, 2, 3, 4, 5],
    };
  },
  components: {
    LineChartsItem,
  },
};
</script>
    
<style lang="less" scoped>
#page7 {
  background-image: url("../assets/page7_bg.png");
  background-size: cover;
  overflow: hidden;
  
  .page-ttitle {
    font-size: 37px;
    line-height: 37px;
    font-weight: 700;
    color: #30744c;
    margin-left: 58px;
    margin-top: 127px;
    display: block;
  }

  .page-content {
    display: flex;
    justify-content: flex-start;
    margin-top: 100px;

    .info-left {
      position: relative;
      width: 360px;

      .left-title {
        color: #231815;
        font-size: 16px;
        margin-left: 59px;
        line-height: 16px;
      }

      .left-section {
        font-size: 13px;
        margin-top: 16px;
        margin-left: 36px;

        .left-section-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-top: 64px;
          padding-bottom: 15px;
          margin-right: 56px;
          border-bottom: #ccc 1px solid;
          box-sizing: border-box;

          .row-mark {
            width: 6px;
            height: 6px;
            border-radius: 12px;
            border: #046626 2px solid;
          }

          .row-right {
            margin-left: 23px;
          }
        }
      }

      .section-bottom-tip {
        display: block;
        font-size: 13px;
        margin-left: 68px;
        margin-top: 15px;
        line-height: 13px;
      }

      .info-left-line {
        width: 294px;
        height: 12px;
        margin-left: 49px;
        margin-top: 50px;
      }

      .left-bottom {
        display: block;
        color: #717071;
        font-size: 12px;
        margin: 33px 0 65px 26px;
      }

      .info-left-right {
        position: absolute;
        right: 0;
        top: 50px;
        width: 37px;
        height: 607px;
      }
    }

    .charts-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 340px;

      .charts-item {
        width: 90%;
        height: 250px;
      }
    }

  }
}
</style>
    