<template>
  <!-- 学科成绩 -->
  <div class="subject-container">
    <project-name class="title-auto" title="学科成绩" />
    <div class="page-header">
      <!-- 头部标题 -->
      <div class="sub-title">学科</div>
      <div class="sub-title">任课老师</div>
      <div class="sub-title" style="text-align: center">成绩</div>
    </div>
    <div class="coruse-list">
      <!-- 主科成绩列表 -->
      <div
        v-for="(item, index) in OpenLession"
        :key="index"
        class="course-main"
      >
        <div class="course-header">
          <!-- 上半 -->
          <div class="sub-item">{{ item.CourseName }}</div>
          <div class="sub-item">{{ item.TeacherName }}</div>
          <div class="sub-item" style="justifycontent: flex-end">
            <span
              class="score-desc"
              :style="{ color: item.TotalStar < 3 ? '#EA6922' : '#303836' }"
              >{{ item.TotalLevel }}</span
            >
            <TermRate :current="item.TotalStar" />
          </div>
        </div>
        <div v-if="getShowBottom(item.CourseName)" class="course-progress">
          <!-- 下半 -->
          <div
            v-for="(obj, idx) in getProgressItem(item.CourseName)"
            :key="idx"
            class="prog-item"
          >
            <span class="item-title">{{ obj.name }}</span>
            <el-progress
              class="el-pro"
              :percentage="obj.value * 2 * 10.0"
              :stroke-width="11"
              :show-text="false"
              :color="customColors"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
    <div class="she-tuan coruse-list">
      <!-- 社团成绩 -->
      <div
        v-for="(item, index) in ScoreShetuan"
        :key="index"
        class="course-main"
      >
        <div class="course-header">
          <!-- 上半 -->
          <div class="sub-item">{{ item.CourseName }}</div>
          <div class="sub-item">{{ item.TeacherName }}</div>
          <div class="sub-item" style="justifycontent: flex-end">
            <span
              class="score-desc"
              :style="{ color: item.TotalStar < 3 ? '#EA6922' : '#303836' }"
              >{{ item.TotalLevel }}</span
            >
            <TermRate :current="item.TotalStar" />
          </div>
        </div>
        <div v-if="getShowBottom(item.CourseName)" class="course-progress">
          <!-- 下半 -->
          <div
            v-for="(obj, idx) in getProgressItem(item.CourseName)"
            :key="idx"
            class="prog-item"
          >
            <span class="item-title">{{ obj.name }}</span>
            <el-progress
              class="el-pro"
              :percentage="obj.value * 2 * 10.0"
              :stroke-width="11"
              :show-text="false"
              :color="customColors"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
    <div class="progress-mark">
      <!-- 底部介绍 -->
      <div class="first-level">
        <el-progress
          class="el-pro"
          :percentage="100"
          :stroke-width="11"
          :show-text="false"
          :color="customColors"
        ></el-progress>
        <span class="item-title">A+</span>
      </div>
      <div class="first-level">
        <el-progress
          class="el-pro"
          :percentage="80"
          :stroke-width="11"
          :show-text="false"
          :color="customColors"
        ></el-progress>
        <span class="item-title">A</span>
      </div>
      <div class="first-level">
        <el-progress
          class="el-pro"
          :percentage="60"
          :stroke-width="11"
          :show-text="false"
          :color="customColors"
        ></el-progress>
        <span class="item-title">B</span>
      </div>
      <div class="first-level">
        <el-progress
          class="el-pro"
          :percentage="40"
          :stroke-width="11"
          :show-text="false"
          :color="customColors"
        ></el-progress>
        <span class="item-title">C</span>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectName from "../components/term/ProjectName.vue";
import TermRate from "../components/term/TermRate.vue";
export default {
  components: {
    ProjectName,
    TermRate,
  },
  props: {
    courseLevelList: [],
    OpenLession: [],
    ScoreShetuan:[]
  },
  data() {
    return {
      customColors: [
        { color: "#ED4908", percentage: 60 },
        { color: "#EB848C", percentage: 80 },
        { color: "#62AADE", percentage: 100 },
        { color: "#7BE2D2", percentage: 100 },
      ],
    };
  },
  methods: {
    getShowBottom(name) {
      if (this.courseLevelList == undefined) {
        return false;
      }
      return (
        this.courseLevelList.filter((item) => item.course == name).length > 0
      );
    },
    getProgressItem(name) {
      if (this.courseLevelList == undefined) {
        return [];
      }
      let list = this.courseLevelList.filter((item) => item.course == name);
      return list.length > 0 ? list[0].keyCompetencies : [];
    },
  },
};
</script>

<style lang="less" scoped>
.subject-container {
  background-color: #fff;

  .title-auto {
    margin: 0 auto;
    margin-top: 42px;
  }

  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    margin: 0 35px;
    margin-top: 39px;

    .sub-title {
      flex: 1;
      color: #303836;
      font-weight: 500;
    }
  }

  .coruse-list {
    // 主科成绩

    .course-main {
      .course-header {
        color: #303836;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 35px;

        .sub-item {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .score-desc {
            margin-right: 16px;
            width: 60px;
            text-align: center;
          }
        }
      }

      .course-progress {
        // 下半
        background: #f5f8fd;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 20px;
        padding: 10px 10px;

        .prog-item {
          // flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 30px;
          height: 20px;

          .item-title {
            color: #3e3a39;
            font-size: 14px;
            font-weight: 440;
          }
        }
      }
    }
  }

  .progress-mark {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 60px;
    right: 40px;

    .first-level {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .item-title {
      color: #3e3a39;
      font-size: 14px;
      font-weight: 440;
      margin-left: 16px;
    }
  }
}

.el-pro {
  max-width: 120px;
  min-width: 60px;
  margin-left: 11px;
}
</style>
