<template>
  <div class="content-main">
    <img src="../assets/term/term_3_tmark.png" alt="#" class="top-mark" />
    <!-- <img src="../assets/term/term_3_bottom.png" alt="#" class="bottom-mark" /> -->
    <!-- 其余 -->
    <honor-monment v-if="Jiang" :prize="prize" />
    <red-medal
      v-if="Zhang && SchoolId != '50'"
      :baseZhang="getbaseZhang"
      :teseZhang="getteseZhang"
      :zhangPhotos="zhangs"
    />
  </div>
</template>

<script>
import HonorMonment from "../components/term/HonorMonment.vue";
import RedMedal from "../components/term/RedMedal.vue";

export default {
  components: {
    HonorMonment,
    RedMedal
  },
  props: {
    getZhang: {},
    zhangPhotos: [],
    prize: [],
    Zhang: true,
    Jiang: true,
    SchoolId: ""
  },
  computed: {
    getbaseZhang() {
      return this.getZhang == undefined ? 0 : this.getZhang.XiaoZhang;
    },
    getteseZhang() {
      return this.getZhang == undefined ? 0 : this.getZhang.ChuyinZhang;
    },
  },
  data() {
    return {
      zhangs: [],
    };
  },
  mounted() {
    this.zhangs = this.zhangPhotos;
  },
};
</script>

<style lang="less" scoped>
.content-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 13px;
  background-color: #fff;
  width: 722px;
  height: 1000px;
  margin: 0 auto;
  position: relative;
  padding-top: 32px;
}

.top-mark {
  position: absolute;
  top: 0px;
  right: -20px;
  width: 162px;
  height: 162px;
  z-index: 0;
}

.bottom-mark {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  z-index: 0;
}
</style>
