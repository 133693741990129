<template>
  <div class="page" id="page4">
    <div class="page-hd"></div>
    <div class="page-body">
      <div class="bd-header">
        <span class="bd-name">何俊杰</span>
        <span class="person-infos">女 ｜ 12岁</span>
      </div>
      <span class="bd-ping">何俊杰同学在本期各方面发展优秀</span>
      <!-- 不同年级照片列表 -->
      <div class="grads-pics">
        <img src="../assets/page4_grade_bg.png" alt="#" class="grads-bg" />
        <div v-for="item in grads" :key="item" class="pics-info">
          <img src alt="#" class="info-img" />
          <span class="img-title">{{ item }}年级</span>
        </div>
      </div>
      <!-- 品德发展 -->
      <div class="section-title">一、品德发展</div>
      <div class="rows-title">1.数字印象</div>
      <!--  数字印象 图表 -->
      <div id="count-chart" class="count-chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-wordcloud";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      grads: [1, 2, 3, 4, 5, 6],
    };
  },
  mounted() {
    var chart = echarts.init(document.getElementById("count-chart"));
    chart.setOption({
      series: [
        {
          type: "wordCloud",
          shape: "circle",
          keepAspect: false,
          // maskImage: maskImage,
          left: "center",
          top: "center",
          width: "100%",
          height: "100%",
          right: null,
          bottom: null,
          sizeRange: [14, 93],
          rotationRange: [-90, 90],
          rotationStep: 90,
          gridSize: 8,
          drawOutOfBound: false,
          shrinkToFit: false,
          layoutAnimation: true,
          textStyle: {
            fontFamily: "sans-serif",
            fontWeight: "bold",
            color: function () {
              return (
                "rgb(" +
                [
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                ].join(",") +
                ")"
              );
            },
          },
          emphasis: {
            focus: "self",
            textStyle: {
              textShadowBlur: 10,
              textShadowColor: "#333",
            },
          },
          data: [
            {
              name: "Sam S Club",
              value: 10000,
              textStyle: {
                color: "black",
              },
              emphasis: {
                textStyle: {
                  color: "red",
                },
              },
            },
            {
              name: "Macys",
              value: 6181,
            },
            {
              name: "Amy Schumer",
              value: 4386,
            },
            {
              name: "Jurassic World",
              value: 4055,
            },
            {
              name: "Charter Communications",
              value: 2467,
            },
            {
              name: "Chick Fil A",
              value: 2244,
            },
            {
              name: "Planet Fitness",
              value: 1898,
            },
            {
              name: "Pitch Perfect",
              value: 1484,
            },
            {
              name: "Express",
              value: 1112,
            },
            {
              name: "Home",
              value: 965,
            },
            {
              name: "Johnny Depp",
              value: 847,
            },
            {
              name: "Lena Dunham",
              value: 582,
            },
            {
              name: "Lewis Hamilton",
              value: 555,
            },
            {
              name: "KXAN",
              value: 550,
            },
            {
              name: "Mary Ellen Mark",
              value: 462,
            },
            {
              name: "Farrah Abraham",
              value: 366,
            },
            {
              name: "Rita Ora",
              value: 360,
            },
            {
              name: "Serena Williams",
              value: 282,
            },
            {
              name: "NCAA baseball tournament",
              value: 273,
            },
            {
              name: "Point Break",
              value: 265,
            },
          ],
        },
      ],
    });
  },
};
</script>

<style lang="less" scoped>
#page4 {
  // background-image: url("../assets/page1_screen_bg.png");
  // background-size: cover;

  .page-hd {
    width: 100%;
    height: 75px;
    background-image: url("../assets/page4_top_head.png");
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    .hd-title {
      font-size: 38px;
      color: white;
    }
  }

  .page-body {
    margin: 65px 57px;

    .bd-header {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 50px;

      .bd-name {
        font-size: 27px;
      }

      .person-infos {
        font-size: 16px;
        margin-left: 28px;
        line-height: 24px;
      }
    }

    .bd-ping {
      color: #666;
      font-size: 16px;
    }

    .grads-pics {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;
      position: relative;
      width: 677px;

      .grads-bg {
        width: 706px;
        height: 121px;
        position: absolute;
        left: -2px;
        top: -18px;
        z-index: 0;
      }

      .pics-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;

        .info-img {
          width: 85px;
          height: 85px;
          border-radius: 62px;
          background-color: rebeccapurple;
        }

        .img-title {
          margin-top: 10px;
        }
      }
    }

    .section-title {
      background-image: url(../assets/page4_title_bgc.png);
      background-size: 16cm 2cm;
      background-repeat: no-repeat;
      background-position-y: 0px;
      font-size: 30px;
      color: green;
      height: 3cm;
      line-height: 3cm;
      margin-top: 60px;
    }

    .count-chart {
      margin: 30px auto;
      background-image: url(../assets/page4_chart_bg.png);
      background-size: cover;

      height: 382px;
      width: 638px;
    }
  }
}
</style>
