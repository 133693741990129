import request from "../utils/request";

const apis = {
  GetTerm: "home/GetReportData2",
  LoginReport: "home/Login2Report",
};

/**
 * 获取单页报告-登录
 * @param {*} parameter
 */
export function loginReport(parameter) {
  return request({
    url: apis.LoginReport + "?StuName=" + parameter.StuName + "&StuPassword=" + parameter.StuPassword,
    method: "get",
    data: parameter,
  });
}

/**
 * 获取单页报告
 * @param {*} parameter
 */
export function getTerm(parameter) {
  // http://report.junmao.top/termHome?StuNo=S4ce4a23c6b0be71b&AcdemicYear=2023-2024&Semester=1
  return request({
    url:
      apis.GetTerm +
      "?StuNo=" +
      parameter.StuNo +
      "&AcdemicYear=" +
      parameter.AcdemicYear +
      "&Semester=" +
      parameter.Semester+
      "&View=" +
      parameter.View,
    method: "get",
    data: parameter,
  });
}
