<template>
  <!-- 劳动实践 -->
  <div id="moral-container">
    <project-name class="title-auto" title="劳动实践" />
    <p class="dev-desc">
      <!-- 发展描述评语 -->
      每个同学都应在劳动观念、劳动能力、劳动习惯品质等方面全面打好基础，注重日常生活劳动习惯的养成，参加生产劳动和服务性劳动，体会劳动人民的艰辛和智慧，传承中华优秀传统文化，成为合格的社会主义建设者和接班人。
    </p>
    <div class="dev-main">
      <!-- 发展主页 -->
      <div class="main-left">
        <div class="left-sction" v-for="item in reals" :key="item.EvalId">
          <div class="section-top">
            <span class="top-title">{{ item.key }}</span>
            <TermRate
              v-if="item.datas.length == 1"
              :current="item.datas[0].Star"
            />
          </div>
          <div v-for="(obj, idx) in item.datas" :key="idx" class="dimens-row">
            <span class="section-span">{{ obj.Dimension }}</span>
            <TermRate v-if="item.datas.length > 1" :current="obj.Star" />
          </div>
        </div>
      </div>
      <div v-if="allItems.length < 15" class="main-right">
        <!-- 右侧风采照 -->
        <img
          v-for="(item, index) in dePhotos"
          :key="index"
          :src="item.Photo"
          alt="#"
          class="dev-imgs"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import ProjectName from "../components/term/ProjectName.vue";
import TermRate from "../components/term/TermRate.vue";
export default {
  components: {
    ProjectName,
    TermRate,
  },
  data() {
    return {
      value2: 5,
      allItems: [],
      reals: [],
    };
  },
  props: {
    evals: [],
    dePhotos: [],
  },
  created() {
    this.getLaos();
  },
  methods: {
    getLaos() {
      var keys = [];
      this.allItems = this.evals.filter((item) => item.Tag == "劳" && item.Course != "科创").sort((a, b) => a.Sort - b.Sort);
      this.allItems.forEach((item) => {
        let index = keys.indexOf(item.Weidu);
        if (index < 0) {
          //如果没有
          keys.push(item.Weidu);
          this.reals.push({ key: item.Weidu, datas: [item] });
        } else {
          this.reals[index].datas.push(item);
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
#moral-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  .title-auto {
    margin: 0 auto;
    margin-top: 32px;
  }

  .dev-desc {
    margin: 16px 24px;
    color: #3e3a39;
    font-size: 18px;
    text-indent: 2em;
  }

  .dev-main {
    display: flex;
    justify-content: flex-start;
    padding-right: 24px;
    flex: 1;

    .left-main-title {
      color: #3e3a39;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 21px;
    }

    .main-left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 32px;

      .left-sction {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 6px 0;

        .section-top {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .top-title {
            color: #3e3a39;
            font-size: 18px;
            font-weight: 500;
            margin-right: 17px;
            margin-left: 20px;
            margin-bottom: 20px;
          }
        }

        .dimens-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;

          .section-span {
            color: #3e3a39;
            line-height: 26px;
            font-size: 18px;
            // text-indent: 1em;
          }
        }
      }
    }

    .main-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-top: 32px;
      margin-left: 16px;

      .dev-imgs {
        width: 186px;
        height: 139px;
        border-radius: 9px;
        object-fit: cover;
        margin-bottom: 27px;
      }
    }
  }
}
</style>

