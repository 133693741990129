<template>
  <div class="page" id="page9">
    <span class="page-ttitle">四、艺术素养</span>
    <span class="total-span">
      <p>
        从终结性评价和过程性评价结果来看，你在艺术素养<span class="total-stuta"
          >整体表现优秀</span
        >。在欣赏评述活动、设计应用活动、造型表现活动和特色发展活动中表现良好，可以在特长展示方面做得更好。
      </p>
      <p>
        你在音乐、美术、书法等艺术类课程中学习优秀，还积极参加学校组织的艺术实践活动，从你平时学习和所提交的艺术作品中作可以看出，你已形成自己的艺术爱好，具备一定的艺术素养，希望你继续努力，全面提升自己感受美、表现美、鉴赏美、创造美的能力。
      </p>
    </span>
    <div class="section-title">1.作品集</div>
    <div class="section-one">一年级代表作品</div>
    <div class="sectoin-rows">
      <!-- 作品列表 -->
      <div class="row-item" v-for="item in rows" :key="item">
        <div class="row-img"></div>
        <span class="row-title">音乐作品</span>
      </div>
    </div>
    <div class="section-one">二年级代表作品</div>
    <div class="sectoin-rows">
      <!-- 作品列表 -->
      <div class="row-item" v-for="item in rows" :key="item">
        <div class="row-img"></div>
        <span class="row-title">音乐作品</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rows: [1, 2, 3, 4],
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#page9 {
  background-image: url("../assets/page6_bg.png");
  background-size: cover;
  padding-left: 59px;
  overflow: hidden;
  
  .page-ttitle {
    font-size: 37px;
    line-height: 37px;
    font-weight: 700;
    color: #30744c;
    margin-top: 127px;
    display: block;
  }

  .total-span {
    display: block;
    margin-top: 106px;
    font-size: 16px;
    width: 540px;

    .total-stuta {
      color: #30744c;
      font-size: 26px;
      font-weight: 700;
    }
  }

  .section-title {
    margin-top: 36px;
    font-size: 16px;
    line-height: 16px;
  }

  .section-one {
    margin-top: 30px;
    line-height: 13px;
    font-size: 13px;
  }

  .sectoin-rows {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .row-item {
      width: 159px;
      height: 151px;
      border: #999 1px solid;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-top: 26px;
      margin-right: 6px;
      .row-img {
        width: 93px;
        height: 93px;
        background-color: #30744c;
        margin-top: 8px;
      }

      .row-title {
        margin-top: 16px;
        font-size: 11px;
        color: #3e3a39;
      }
    }
  }
}
</style>