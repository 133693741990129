<template>
  <!-- 科创实践 -->
  <div class="science-container">
    <project-name class="page-title" title="科创实践" />
    <p class="dev-desc">
      <!-- 科创描述评语 -->
      为了进一步促进学生发现科学原理，热爱科学、走近科学，利用科学解决生活中的实际问题。同时在“双减”背景下，我校积极响应市、区教育局号召，努力践行“五育”并举，结合各年级的教学实际分年段布置了有针对性的科技实践作业，作业形式灵活、多样，倡导同学们利用生活中随处可见的无毒无害的废旧物品进行设计与制作，丰富了学生的学习生活。让我们一起看一看吧！
    </p>
    <div class="main-content">
      <div class="main-left">
        <div class="section-main" v-for="item in reals" :key="item.EvalId">
          <div class="section-top">
            <span class="top-title">{{ item.key }}</span>
            <TermRate
              v-if="item.datas.length == 1"
              :current="item.datas[0].Star"
            />
          </div>
          <div v-for="(obj, idx) in item.datas" :key="idx" class="dimens-row">
            <span class="section-span">{{ obj.Dimension }}</span>
            <TermRate v-if="item.datas.length > 1" :current="obj.Star" />
          </div>
        </div>
      </div>
      <!-- <div v-if="allItems.length < 12" class="main-bottom"> -->
      <div class="main-bottom">
        <!-- 风采照 -->
        <div class="bottom-imgs" v-for="(item, index) in kePhotos" :key="index">
          <img :src="item.Photo" alt="#" class="dev-imgs" />
          <span class="img-title">{{ item.Title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectName from "../components/term/ProjectName.vue";
import TermRate from "../components/term/TermRate.vue";
export default {
  components: {
    ProjectName,
    TermRate,
  },
  data() {
    return {
      value2: 5,
      reals: [],
      allItems: [],
    };
  },
  props: {
    evals: [],
    kePhotos: [],
  },
  created() {
    this.getLaos();
  },
  methods: {
    getLaos() {
      var keys = [];
      this.allItems = this.evals
        .filter((item) => item.Tag == "劳" && item.Course == "科创")
        .sort((a, b) => a.Sort - b.Sort);
      this.allItems.forEach((item) => {
        let index = keys.indexOf(item.Weidu);
        if (index < 0) {
          //如果没有
          keys.push(item.Weidu);
          this.reals.push({ key: item.Weidu, datas: [item] });
        } else {
          this.reals[index].datas.push(item);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.science-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-title {
    margin: 24px auto;
  }

  .dev-desc {
    margin: 0 24px;
    margin-top: 16px;
    color: #3e3a39;
    font-size: 16px;
    text-indent: 2em;
  }

  .main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }

  .section-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 6px 12px;

    .section-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .top-title {
        color: #3e3a39;
        font-size: 16px;
        font-weight: 500;
        margin-right: 17px;
        margin-left: 24px;
      }
    }

    .dimens-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .section-span {
        color: #3e3a39;
        line-height: 21px;
        font-size: 13px;
        text-indent: 2em;
      }
    }
  }

  .main-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;

    .bottom-imgs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-bottom: 17px;
      width: 240px;

      .dev-imgs {
        width: 180px;
        height: 110px;
        border-radius: 9px;
        object-fit: cover;
      }

      .img-title {
        font-size: 13px;
        margin: 6px 0;
      }
    }
  }
}
</style>
